import { ImageLiteRadars, ImageRadars } from 'types/imageRadars';

type RadarImagesConfig = {
	RenderText?: string;
	RenderBranding?: string;
	RenderBackground?: string;
	SubType?: string;
	DefaultWidth?: string;
	DefaultHeight?: string;
	DefaultForecast?: string;
	DefaultHistory?: string;
	DefaultSkip?: string;
};

export const radarImagesConfig = {
	[ImageRadars.RadarMapRainNL]: {
		DefaultHeight: 512,
		MaxHeight: 512,
		DefaultWidth: 550,
		MaxWidth: 550,
		DefaultHistory: 3,
		MaxHistory: 36,
		DefaultForecast: 3,
		MaxForecast: 36,
		Skip: 1,
		Opacity: 0.8,
		RenderBackground: true,
		SourceFileTemplate: 'yyyyMMddHHmm',
		HistoryPath: 'nl/rain/current/merc/{0}.png',
		ForecastPath:
			'nl/rain/forecast/{runsFolder}merc/{runTimestamp}{fileTimestamp}.png',
		BackgroundPath: 'images/map-nl-550x512-mercator.gif',
		OrangeBackgroundPath: 'images/map-nl-550x512-mercator.oranje.gif',
		LogoPath: 'images/logo-white.png',
		ImageInterval: 5,
		IsUTC: true,
		GifDelay: 500,
		HistoryProcessorName: 'render_rain_nl',
		ForecastProcessorName: 'render_rain_nl',
		Projection: 'merc',
		Archives: [
			{
				StartTimestamp: '2008-06-18T11:25:00',
				EndTimestamp: '2018-11-30T23:59:59',
				ArchivePath: 'nl-rain/',
				ArchiveFileTemplate: '{0}.png',
				ArchiveFileDateFormat: 'yyyyMMddHHmm',
				MimeType: 'image/png',
				IsUTC: false,
				IsLegacy: true,
			},
			{
				StartTimestamp: '2018-12-01T00:00:00',
				EndTimestamp: '2100-01-01T00:00:00',
				ArchivePath: 'nl/rain/current/merc/',
				ArchiveFileTemplate: '{0}.png',
				ArchiveFileDateFormat: 'yyyyMMddHHmm',
				MimeType: 'image/png',
				IsUTC: true,
				IsLegacy: false,
				DrawBackground: true,
			},
		],
	},
	[ImageRadars.RadarMapRainBE]: {
		DefaultHeight: 512,
		MaxHeight: 512,
		DefaultWidth: 550,
		MaxWidth: 550,
		DefaultHistory: 3,
		MaxHistory: 36,
		DefaultForecast: 3,
		MaxForecast: 36,
		Skip: 1,
		Opacity: 0.8,
		RenderBackground: true,
		SourceFileTemplate: 'yyyyMMddHHmm',
		HistoryPath: 'be/rain/current/merc/{0}.png',
		ForecastPath:
			'be/rain/forecast/{runsFolder}merc/{runTimestamp}{fileTimestamp}.png',
		BackgroundPath: 'images/map-be-550x512-mercator.gif',
		LogoPath: 'images/logo-white.png',
		ImageInterval: 5,
		IsUTC: true,
		GifDelay: 500,
		HistoryProcessorName: 'render_rain_be',
		ForecastProcessorName: 'render_rain_be',
		Projection: 'merc',
		Archives: [
			{
				StartTimestamp: '2018-09-14T08:35:00',
				EndTimestamp: '2100-01-01T00:00:00',
				ArchivePath: 'nl/rain/current/merc/',
				ArchiveFileTemplate: '{0}.png',
				ArchiveFileDateFormat: 'yyyyMMddHHmm',
				MimeType: 'image/png',
				IsUTC: true,
				IsLegacy: false,
				DrawBackground: true,
			},
		],
	},
	[ImageRadars.RadarMapRainEU]: {
		DefaultHeight: 512,
		MaxHeight: 512,
		DefaultWidth: 550,
		MaxWidth: 550,
		DefaultHistory: 3,
		MaxHistory: 12,
		DefaultForecast: 3,
		MaxForecast: 12,
		Skip: 0,
		RenderBackground: true,
		SourceFileTemplate: 'yyyyMMddHHmm',
		HistoryPath: 'eu/rain/current/merc/{0}.png',
		ForecastPath:
			'eu/rain/forecast/{runsFolder}merc/{runTimestamp}{fileTimestamp}.png',
		BackgroundPath: 'images/map-eu-550x512-mercator.png',
		LogoPath: 'images/logo-white.png',
		ImageInterval: 15,
		IsUTC: true,
		HistoryProcessorName: 'render_rain_eu',
		ForecastProcessorName: 'render_rain_eu',
		Projection: 'merc',
		Archives: [
			{
				StartTimestamp: '2008-06-18T11:25:00',
				EndTimestamp: '2018-11-30T23:59:59',
				ArchivePath: 'eu-rain/',
				ArchiveFileTemplate: '{0}.png',
				ArchiveFileDateFormat: 'yyyyMMddHHmm',
				MimeType: 'image/png',
				IsUTC: false,
				IsLegacy: true,
			},
			{
				StartTimestamp: '2018-12-01T00:00:00',
				EndTimestamp: '2100-01-01T00:00:00',
				ArchivePath: 'eu/rain/current/merc/',
				ArchiveFileTemplate: '{0}.png',
				ArchiveFileDateFormat: 'yyyyMMddHHmm',
				MimeType: 'image/png',
				IsUTC: true,
				IsLegacy: false,
				DrawBackground: true,
			},
		],
	},
	[ImageRadars.RadarMapHailNL]: {
		DefaultHeight: 512,
		MaxHeight: 512,
		DefaultWidth: 550,
		MaxWidth: 550,
		DefaultHistory: 4,
		MaxHistory: 12,
		DefaultForecast: 0,
		MaxForecast: 0,
		Skip: 1,
		RenderBackground: true,
		SourceFileTemplate: 'yyyyMMddHHmm',
		HistoryPath: 'nl/hail/current/merc/{0}.png',
		BackgroundPath: 'images/map-nl-550x512-mercator.gif',
		LogoPath: 'images/logo-white.png',
		ImageInterval: 5,
		IsUTC: true,
		HistoryProcessorName: 'render_hail_nl',
		Projection: 'merc',
	},
	[ImageRadars.RadarMapHailBE]: {
		DefaultHeight: 512,
		MaxHeight: 512,
		DefaultWidth: 550,
		MaxWidth: 550,
		DefaultHistory: 4,
		MaxHistory: 12,
		DefaultForecast: 0,
		MaxForecast: 0,
		Skip: 1,
		RenderBackground: true,
		SourceFileTemplate: 'yyyyMMddHHmm',
		HistoryPath: 'be/hail/current/merc/{0}.png',
		BackgroundPath: 'images/map-be-550x512-mercator.gif',
		LogoPath: 'images/logo-white.png',
		ImageInterval: 5,
		IsUTC: true,
		HistoryProcessorName: 'render_hail_be',
		Projection: 'merc',
	},
	[ImageRadars.RadarMapLightningNL]: {
		DefaultHeight: 512,
		MaxHeight: 512,
		DefaultWidth: 550,
		MaxWidth: 550,
		DefaultHistory: 4,
		MaxHistory: 12,
		DefaultForecast: 0,
		MaxForecast: 0,
		Skip: 1,
		Opacity: 0.8,
		RenderBackground: true,
		SourceFileTemplate: 'yyyyMMddHHmm',
		HistoryPath: 'nl/lightning/current/merc/{0}.png',
		ForecastPath: 'nl/lightning/current/merc/{fileTimestamp}.png',
		BackgroundPath: 'images/map-nl-550x512-mercator.gif',
		LogoPath: 'images/logo-white.png',
		ImageInterval: 5,
		IsUTC: true,
		GifDelay: 500,
		HistoryProcessorName: 'render_lightning_nl',
		Projection: 'merc',
		Archives: [
			{
				StartTimestamp: '2008-01-01T00:00:00',
				EndTimestamp: '2012-12-31T23:59:59',
				ArchivePath: 'nl-bliksem/',
				ArchiveFileTemplate: '{0}.gif',
				ArchiveFileDateFormat: 'yyyyMMddHHmm',
				MimeType: 'image/gif',
				IsUTC: false,
				IsLegacy: true,
			},
			{
				StartTimestamp: '2013-01-01T00:00:00',
				EndTimestamp: '2015-12-30T20:30:00',
				ArchivePath: 'nl-bliksem/',
				ArchiveFileTemplate: '{0}.png',
				ArchiveFileDateFormat: 'yyyyMMddHHmm',
				MimeType: 'image/png',
				IsUTC: false,
				IsLegacy: true,
			},
			{
				StartTimestamp: '2016-08-15T16:20:00',
				EndTimestamp: '2100-01-01T00:00:00',
				ArchivePath: 'nl/lightning/',
				ArchiveFileTemplate: '{0}.png',
				ArchiveFileDateFormat: 'yyyyMMddHHmm',
				MimeType: 'image/png',
				IsUTC: true,
				IsLegacy: false,
			},
		],
	},
	[ImageRadars.RadarMapLightningBE]: {
		DefaultHeight: 512,
		MaxHeight: 512,
		DefaultWidth: 550,
		MaxWidth: 550,
		DefaultHistory: 4,
		MaxHistory: 12,
		DefaultForecast: 0,
		MaxForecast: 0,
		Skip: 1,
		Opacity: 0.8,
		RenderBackground: true,
		SourceFileTemplate: 'yyyyMMddHHmm',
		HistoryPath: 'be/lightning/current/merc/{0}.png',
		ForecastPath: 'be/lightning/current/merc/{fileTimestamp}.png',
		BackgroundPath: 'images/map-be-550x512-mercator.gif',
		LogoPath: 'images/logo-white.png',
		ImageInterval: 5,
		IsUTC: true,
		GifDelay: 500,
		HistoryProcessorName: 'render_lightning_be',
		Projection: 'merc',
		Archives: [
			{
				StartTimestamp: '2016-08-15T16:20:00',
				EndTimestamp: '2100-01-01T00:00:00',
				ArchivePath: 'be/lightning/',
				ArchiveFileTemplate: '{0}.png',
				ArchiveFileDateFormat: 'yyyyMMddHHmm',
				MimeType: 'image/png',
				IsUTC: true,
				IsLegacy: false,
			},
		],
	},
	[ImageRadars.RadarMapDrizzleNL]: {
		DefaultHeight: 512,
		MaxHeight: 512,
		DefaultWidth: 550,
		MaxWidth: 550,
		DefaultHistory: 12,
		MaxHistory: 36,
		DefaultForecast: 12,
		MaxForecast: 36,
		Skip: 0,
		Opacity: 0.8,
		RenderBackground: true,
		SourceFileTemplate: 'yyyyMMddHHmm',
		HistoryPath: 'nl/drizzle/current/merc/{0}.png',
		ForecastPath:
			'nl/drizzle/forecast/{runsFolder}merc/{runTimestamp}{fileTimestamp}.png',
		BackgroundPath: 'images/map-nl-550x512-mercator.gif',
		LogoPath: 'images/logo-white.png',
		ImageInterval: 5,
		HistoryProcessorName: 'render_drizzle_nl',
		ForecastProcessorName: 'render_drizzle_nl',
		Projection: 'merc',
		IsUTC: true,
	},
	[ImageRadars.RadarMapDrizzleBE]: {
		DefaultHeight: 512,
		MaxHeight: 512,
		DefaultWidth: 550,
		MaxWidth: 550,
		DefaultHistory: 12,
		MaxHistory: 36,
		DefaultForecast: 12,
		MaxForecast: 36,
		Skip: 0,
		Opacity: 0.8,
		RenderBackground: true,
		SourceFileTemplate: 'yyyyMMddHHmm',
		HistoryPath: 'be/drizzle/current/merc/{0}.png',
		ForecastPath:
			'be/drizzle/forecast/{runsFolder}merc/{runTimestamp}{fileTimestamp}.png',
		BackgroundPath: 'images/map-be-550x512-mercator.gif',
		LogoPath: 'images/logo-white.png',
		ImageInterval: 5,
		HistoryProcessorName: 'render_drizzle_be',
		ForecastProcessorName: 'render_drizzle_be',
		Projection: 'merc',
		IsUTC: true,
	},
	[ImageRadars.RadarMapSunNL]: {
		DefaultHeight: 512,
		MaxHeight: 512,
		DefaultWidth: 550,
		MaxWidth: 550,
		DefaultHistory: 3,
		MaxHistory: 12,
		DefaultForecast: 3,
		MaxForecast: 36,
		Skip: 1,
		Opacity: 0.8,
		RenderBackground: true,
		SourceFileTemplate: 'yyyyMMddHHmm',
		HistoryPath: 'nl/sun/current/merc/{0}.png',
		ForecastPath:
			'nl/sun/forecast/{runsFolder}merc/{runTimestamp}{fileTimestamp}.png',
		BackgroundPath: 'images/map-nl-550x512-mercator.gif',
		LogoPath: 'images/logo-white.png',
		ImageInterval: 5,
		HistoryProcessorName: 'render_cloud_nl',
		ForecastProcessorName: 'render_cloud_nl',
		Projection: 'merc',
		IsUTC: true,
	},
	[ImageRadars.RadarMapSunBE]: {
		DefaultHeight: 512,
		MaxHeight: 512,
		DefaultWidth: 550,
		MaxWidth: 550,
		DefaultHistory: 3,
		MaxHistory: 12,
		DefaultForecast: 3,
		MaxForecast: 36,
		Skip: 1,
		Opacity: 0.8,
		RenderBackground: true,
		SourceFileTemplate: 'yyyyMMddHHmm',
		HistoryPath: 'be/sun/current/merc/{0}.png',
		ForecastPath:
			'be/sun/forecast/{runsFolder}merc/{runTimestamp}{fileTimestamp}.png',
		BackgroundPath: 'images/map-be-550x512-mercator.gif',
		LogoPath: 'images/logo-white.png',
		ImageInterval: 5,
		HistoryProcessorName: 'render_cloud_be',
		ForecastProcessorName: 'render_cloud_be',
		Projection: 'merc',
		IsUTC: true,
	},
	[ImageRadars.RadarMapCloudNL]: {
		DefaultHeight: 512,
		MaxHeight: 512,
		DefaultWidth: 550,
		MaxWidth: 550,
		DefaultHistory: 3,
		MaxHistory: 12,
		DefaultForecast: 3,
		MaxForecast: 36,
		Skip: 1,
		Opacity: 1,
		RenderBackground: true,
		SourceFileTemplate: 'yyyyMMddHHmm',
		HistoryPath: 'nl/cloud/current/merc/{0}.png',
		ForecastPath:
			'nl/cloud/forecast/{runsFolder}merc/{runTimestamp}{fileTimestamp}.png',
		BackgroundPath: 'images/map-nl-550x512-mercator.gif',
		LogoPath: 'images/logo-white.png',
		ImageInterval: 5,
		HistoryProcessorName: 'render_cloud_nl',
		ForecastProcessorName: 'render_cloud_nl',
		Projection: 'merc',
		IsUTC: true,
	},
	[ImageRadars.RadarMapCloudBE]: {
		DefaultHeight: 512,
		MaxHeight: 512,
		DefaultWidth: 550,
		MaxWidth: 550,
		DefaultHistory: 3,
		MaxHistory: 12,
		DefaultForecast: 3,
		MaxForecast: 36,
		Skip: 1,
		Opacity: 1,
		RenderBackground: true,
		SourceFileTemplate: 'yyyyMMddHHmm',
		HistoryPath: 'be/cloud/current/merc/{0}.png',
		ForecastPath:
			'be/cloud/forecast/{runsFolder}merc/{runTimestamp}{fileTimestamp}.png',
		BackgroundPath: 'images/map-be-550x512-mercator.gif',
		LogoPath: 'images/logo-white.png',
		ImageInterval: 5,
		HistoryProcessorName: 'render_cloud_be',
		ForecastProcessorName: 'render_cloud_be',
		Projection: 'merc',
		IsUTC: true,
	},
	[ImageRadars.RadarMapCloudEU]: {
		DefaultHeight: 512,
		MaxHeight: 512,
		DefaultWidth: 550,
		MaxWidth: 550,
		DefaultHistory: 12,
		MaxHistory: 36,
		DefaultForecast: 0,
		MaxForecast: 0,
		Skip: 0,
		Opacity: 0.8,
		RenderBackground: true,
		SourceFileTemplate: 'yyyyMMddHHmm',
		HistoryPath: 'eu/lightning/current/merc/{0}.png',
		ForecastPath:
			'eu/lightning/forecast/{runsFolder}merc/{runTimestamp}{fileTimestamp}.png',
		BackgroundPath: 'images/map-eu-550x512-mercator.png',
		LogoPath: 'images/logo-white.png',
		ImageInterval: 15,
		HistoryProcessorName: 'render_lightning_eu',
		ForecastProcessorName: 'render_lightning_eu',
		Projection: 'merc',
		IsUTC: true,
	},
	[ImageRadars.RadarMapSnowNL]: {
		DefaultHeight: 512,
		MaxHeight: 512,
		DefaultWidth: 550,
		MaxWidth: 550,
		DefaultHistory: 6,
		MaxHistory: 36,
		DefaultForecast: 0,
		MaxForecast: 36,
		Skip: 1,
		Opacity: 0.8,
		RenderBackground: true,
		SourceFileTemplate: 'yyyyMMddHHmm',
		HistoryPath: 'nl/snow/current/merc/{0}.png',
		ForecastPath:
			'nl/snow/forecast/{runsFolder}merc/{runTimestamp}{fileTimestamp}.png',
		BackgroundPath: 'images/map-nl-550x512-mercator.gif',
		LogoPath: 'images/logo-white.png',
		ImageInterval: 5,
		HistoryProcessorName: 'render_snow_nl',
		ForecastProcessorName: 'render_snow_forecast_nl',
		Projection: 'merc',
		IsUTC: true,
	},
	[ImageRadars.RadarMapSnowBE]: {
		DefaultHeight: 512,
		MaxHeight: 512,
		DefaultWidth: 550,
		MaxWidth: 550,
		DefaultHistory: 6,
		MaxHistory: 36,
		DefaultForecast: 0,
		MaxForecast: 36,
		Skip: 1,
		Opacity: 0.8,
		RenderBackground: true,
		SourceFileTemplate: 'yyyyMMddHHmm',
		HistoryPath: 'be/snow/current/merc/{0}.png',
		ForecastPath:
			'be/snow/forecast/{runsFolder}merc/{runTimestamp}{fileTimestamp}.png',
		BackgroundPath: 'images/map-be-550x512-mercator.gif',
		LogoPath: 'images/logo-white.png',
		ImageInterval: 5,
		HistoryProcessorName: 'render_snow_be',
		ForecastProcessorName: 'render_snow_be',
		Projection: 'merc',
		IsUTC: true,
	},
	[ImageLiteRadars.RadarMapSnow5mBE]: {
		DefaultHeight: 512,
		MaxHeight: 512,
		DefaultWidth: 550,
		MaxWidth: 550,
		DefaultHistory: 6,
		MaxHistory: 36,
		DefaultForecast: 0,
		MaxForecast: 36,
		Skip: 1,
		Opacity: 0.8,
		RenderBackground: true,
		SourceFileTemplate: 'yyyyMMddHHmm',
		HistoryPath: 'be/snow/current/merc/{0}.png',
		ForecastPath:
			'be/snow/forecast/{runsFolder}merc/{runTimestamp}{fileTimestamp}.png',
		BackgroundPath: 'images/map-be-550x512-mercator.gif',
		LogoPath: 'images/logo-white.png',
		ImageInterval: 5,
		HistoryProcessorName: 'render_snow_be',
		ForecastProcessorName: 'render_snow_be',
		Projection: 'merc',
		IsUTC: true,
	},
	[ImageRadars.RadarMapSnowEU]: {
		DefaultHeight: 512,
		MaxHeight: 512,
		DefaultWidth: 550,
		MaxWidth: 550,
		DefaultHistory: 3,
		MaxHistory: 12,
		DefaultForecast: 3,
		MaxForecast: 12,
		Skip: 1,
		Opacity: 0.8,
		RenderBackground: true,
		SourceFileTemplate: 'yyyyMMddHHmm',
		HistoryPath: 'eu/snow/current/merc/{0}.png',
		ForecastPath:
			'eu/snow/forecast/{runsFolder}merc/{runTimestamp}{fileTimestamp}.png',
		BackgroundPath: 'images/map-eu-550x512-mercator.png',
		LogoPath: 'images/logo-white.png',
		ImageInterval: 15,
		HistoryProcessorName: 'render_snow_eu',
		ForecastProcessorName: 'render_snow_eu',
		Projection: 'merc',
		IsUTC: true,
	},
	[ImageRadars.RadarMapRain3HourForecastNL]: {
		DefaultHeight: 560,
		MaxHeight: 512,
		DefaultWidth: 765,
		MaxWidth: 700,
		DefaultHistory: 0,
		MaxHistory: 0,
		DefaultForecast: 12,
		MaxForecast: 36,
		Skip: 1,
		Opacity: 0.8,
		RenderBackground: true,
		SourceFileTemplate: 'yyyyMMddHHmm',
		ForecastPath:
			'nl/rain/forecast/{runsFolder}merc/{runTimestamp}{fileTimestamp}.png',
		BackgroundPath: 'images/map-nl-550x512-mercator.gif',
		LogoPath: 'images/logo-white.png',
		ImageInterval: 5,
		HistoryProcessorName: 'render_rain_nl',
		ForecastProcessorName: 'render_rain_nl',
		Projection: 'merc',
		IsUTC: true,
	},
	[ImageRadars.RadarMapRain24HourHistoryNL]: {
		DefaultHeight: 512,
		MaxHeight: 512,
		DefaultWidth: 550,
		MaxWidth: 550,
		DefaultHistory: 24,
		MaxHistory: 24,
		DefaultForecast: 0,
		MaxForecast: 0,
		Skip: 0,
		Opacity: 0.8,
		RenderBackground: true,
		SourceFileTemplate: 'yyyyMMddHHmm',
		HistoryPath: 'nl/rain/current/merc/{0}.png',
		ForecastPath:
			'nl/rain/forecast/{runsFolder}merc/{runTimestamp}{fileTimestamp}.png',
		BackgroundPath: 'images/map-nl-550x512-mercator.gif',
		LogoPath: 'images/logo-white.png',
		ImageInterval: 60,
		HistoryProcessorName: 'render_rain_nl',
		ForecastProcessorName: 'render_rain_nl',
		Projection: 'merc',
		IsUTC: true,
	},
	[ImageRadars.RadarMapRain24HourForecastNL]: {
		DefaultHeight: 512,
		MaxHeight: 512,
		DefaultWidth: 550,
		MaxWidth: 550,
		DefaultHistory: 0,
		MaxHistory: 0,
		DefaultForecast: 12,
		MaxForecast: 48,
		Skip: 0,
		Opacity: 0.8,
		RenderBackground: true,
		SourceFileTemplate: 'yyyyMMddHHmm',
		ForecastPath:
			'nl/harmonie/rain/{runsFolder}merc/{runTimestamp}{fileTimestamp}.png',
		BackgroundPath: 'images/map-nl-550x512-mercator.gif',
		LogoPath: 'images/logo-white.png',
		ImageInterval: 60,
		ForecastProcessorName: 'renderer_layers_rain_nl_harmonie',
		Projection: 'merc',
		IsUTC: true,
	},
	[ImageRadars.RadarMapRain24HourForecastWebmercatorNL]: {
		DefaultHeight: 915,
		MaxHeight: 915,
		DefaultWidth: 1058,
		MaxWidth: 1058,
		DefaultHistory: 0,
		MaxHistory: 0,
		DefaultForecast: 12,
		MaxForecast: 48,
		Skip: 0,
		Opacity: 0.8,
		RenderBackground: true,
		SourceFileTemplate: 'yyyyMMddHHmm',
		ForecastPath:
			'nl/harmonie/rain/{runsFolder}webm/{runTimestamp}{fileTimestamp}.png',
		BackgroundPath: 'images/map-nl-1058x916-webmercator.png',
		LogoPath: 'images/logo-white.png',
		ImageInterval: 60,
		ForecastProcessorName: 'renderer_layers_rain_nl_harmonie',
		Projection: 'webm',
		IsUTC: true,
	},
	[ImageRadars.RadarMapRain24HourForecastBE]: {
		DefaultHeight: 512,
		MaxHeight: 512,
		DefaultWidth: 550,
		MaxWidth: 550,
		DefaultHistory: 0,
		MaxHistory: 0,
		DefaultForecast: 12,
		MaxForecast: 48,
		Skip: 0,
		Opacity: 0.8,
		RenderBackground: true,
		SourceFileTemplate: 'yyyyMMddHHmm',
		ForecastPath:
			'be/harmonie/rain/{runsFolder}merc/{runTimestamp}{fileTimestamp}.png',
		BackgroundPath: 'images/map-be-550x512-mercator.gif',
		LogoPath: 'images/logo-white.png',
		ImageInterval: 60,
		ForecastProcessorName: 'renderer_layers_rain_be_harmonie',
		Projection: 'merc',
		IsUTC: true,
	},
	[ImageLiteRadars.RadarMapRain1hBE]: {
		DefaultHeight: 949,
		MaxHeight: 949,
		DefaultWidth: 1058,
		MaxWidth: 1058,
		DefaultHistory: 0,
		MaxHistory: 0,
		DefaultForecast: 12,
		MaxForecast: 48,
		Skip: 0,
		Opacity: 0.8,
		RenderBackground: true,
		SourceFileTemplate: 'yyyyMMddHHmm',
		ForecastPath:
			'be/harmonie/rain/{runsFolder}webm/{runTimestamp}{fileTimestamp}.png',
		BackgroundPath: 'images/map-be-1058x949-webmercator.png',
		LogoPath: 'images/logo-white.png',
		ImageInterval: 60,
		ForecastProcessorName: 'renderer_layers_rain_be_harmonie',
		Projection: 'webm',
		IsUTC: true,
	},
	[ImageRadars.RadarMapSun24HourForecastNL]: {
		DefaultHeight: 512,
		MaxHeight: 512,
		DefaultWidth: 550,
		MaxWidth: 550,
		DefaultHistory: 0,
		MaxHistory: 0,
		DefaultForecast: 12,
		MaxForecast: 48,
		Skip: 0,
		Opacity: 0.8,
		RenderBackground: true,
		SourceFileTemplate: 'yyyyMMddHHmm',
		ForecastPath:
			'nl/harmonie/sun/{runsFolder}merc/{runTimestamp}{fileTimestamp}.png',
		BackgroundPath: 'images/map-nl-550x512-mercator.gif',
		LogoPath: 'images/logo-white.png',
		ImageInterval: 60,
		ForecastProcessorName: 'render_cloud_nl_harmonie',
		Projection: 'merc',
		IsUTC: true,
	},
	[ImageRadars.RadarMapSun24HourForecastWebmercatorNL]: {
		DefaultHeight: 915,
		MaxHeight: 915,
		DefaultWidth: 1058,
		MaxWidth: 1058,
		DefaultHistory: 0,
		MaxHistory: 0,
		DefaultForecast: 12,
		MaxForecast: 48,
		Skip: 0,
		Opacity: 0.8,
		RenderBackground: true,
		SourceFileTemplate: 'yyyyMMddHHmm',
		ForecastPath:
			'nl/harmonie/sun/{runsFolder}webm/{runTimestamp}{fileTimestamp}.png',
		BackgroundPath: 'images/map-nl-1058x916-webmercator.png',
		LogoPath: 'images/logo-white.png',
		ImageInterval: 60,
		ForecastProcessorName: 'render_cloud_nl_harmonie',
		Projection: 'webm',
		IsUTC: true,
	},
	[ImageRadars.RadarMapSun24HourForecastBE]: {
		DefaultHeight: 512,
		MaxHeight: 512,
		DefaultWidth: 550,
		MaxWidth: 550,
		DefaultHistory: 0,
		MaxHistory: 0,
		DefaultForecast: 12,
		MaxForecast: 48,
		Skip: 0,
		Opacity: 0.8,
		RenderBackground: true,
		SourceFileTemplate: 'yyyyMMddHHmm',
		ForecastPath:
			'be/harmonie/sun/{runsFolder}merc/{runTimestamp}{fileTimestamp}.png',
		BackgroundPath: 'images/map-be-550x512-mercator.gif',
		LogoPath: 'images/logo-white.png',
		ImageInterval: 60,
		ForecastProcessorName: 'render_cloud_be_harmonie',
		Projection: 'merc',
		IsUTC: true,
	},
	[ImageRadars.RadarMapSun24HourForecastWebmercatorBE]: {
		DefaultHeight: 949,
		MaxHeight: 949,
		DefaultWidth: 1058,
		MaxWidth: 1058,
		DefaultHistory: 0,
		MaxHistory: 0,
		DefaultForecast: 12,
		MaxForecast: 48,
		Skip: 0,
		Opacity: 0.8,
		RenderBackground: true,
		SourceFileTemplate: 'yyyyMMddHHmm',
		ForecastPath:
			'be/harmonie/sun/{runsFolder}webm/{runTimestamp}{fileTimestamp}.png',
		BackgroundPath: 'images/map-be-1058x949-webmercator.png',
		LogoPath: 'images/logo-white.png',
		ImageInterval: 60,
		ForecastProcessorName: 'render_cloud_be_harmonie',
		Projection: 'webm',
		IsUTC: true,
	},
	[ImageRadars.RadarMapCloud24HourForecastNL]: {
		DefaultHeight: 512,
		MaxHeight: 512,
		DefaultWidth: 550,
		MaxWidth: 550,
		DefaultHistory: 0,
		MaxHistory: 0,
		DefaultForecast: 12,
		MaxForecast: 48,
		Skip: 0,
		Opacity: 0.8,
		RenderBackground: true,
		SourceFileTemplate: 'yyyyMMddHHmm',
		ForecastPath:
			'nl/harmonie/cloud/{runsFolder}merc/{runTimestamp}{fileTimestamp}.png',
		BackgroundPath: 'images/map-nl-550x512-mercator.gif',
		LogoPath: 'images/logo-white.png',
		ImageInterval: 60,
		ForecastProcessorName: 'render_cloud_nl_harmonie',
		Projection: 'merc',
		IsUTC: true,
	},
	[ImageRadars.RadarMapCloud24HourForecastWebmercatorNL]: {
		DefaultHeight: 915,
		MaxHeight: 915,
		DefaultWidth: 1058,
		MaxWidth: 1058,
		DefaultHistory: 0,
		MaxHistory: 0,
		DefaultForecast: 12,
		MaxForecast: 48,
		Skip: 0,
		Opacity: 0.8,
		RenderBackground: true,
		SourceFileTemplate: 'yyyyMMddHHmm',
		ForecastPath:
			'nl/harmonie/cloud/{runsFolder}webm/{runTimestamp}{fileTimestamp}.png',
		BackgroundPath: 'images/map-nl-1058x916-webmercator.png',
		LogoPath: 'images/logo-white.png',
		ImageInterval: 60,
		ForecastProcessorName: 'render_cloud_nl_harmonie',
		Projection: 'webm',
		IsUTC: true,
	},
	[ImageRadars.RadarMapCloud24HourForecastBE]: {
		DefaultHeight: 512,
		MaxHeight: 512,
		DefaultWidth: 550,
		MaxWidth: 550,
		DefaultHistory: 0,
		MaxHistory: 0,
		DefaultForecast: 12,
		MaxForecast: 48,
		Skip: 0,
		Opacity: 0.8,
		RenderBackground: true,
		SourceFileTemplate: 'yyyyMMddHHmm',
		ForecastPath:
			'be/harmonie/cloud/{runsFolder}merc/{runTimestamp}{fileTimestamp}.png',
		BackgroundPath: 'images/map-be-550x512-mercator.gif',
		LogoPath: 'images/logo-white.png',
		ImageInterval: 60,
		ForecastProcessorName: 'render_cloud_be_harmonie',
		Projection: 'merc',
		IsUTC: true,
	},
	[ImageRadars.RadarMapCloud24HourForecastWebmercatorBE]: {
		DefaultHeight: 949,
		MaxHeight: 949,
		DefaultWidth: 1058,
		MaxWidth: 1058,
		DefaultHistory: 0,
		MaxHistory: 0,
		DefaultForecast: 12,
		MaxForecast: 48,
		Skip: 0,
		Opacity: 0.8,
		RenderBackground: true,
		SourceFileTemplate: 'yyyyMMddHHmm',
		ForecastPath:
			'be/harmonie/cloud/{runsFolder}webm/{runTimestamp}{fileTimestamp}.png',
		BackgroundPath: 'images/map-be-1058x949-webmercator.png',
		LogoPath: 'images/logo-white.png',
		ImageInterval: 60,
		ForecastProcessorName: 'render_cloud_be_harmonie',
		Projection: 'webm',
		IsUTC: true,
	},
	[ImageRadars.RadarMapTemperature24HourForecastNL]: {
		DefaultHeight: 512,
		MaxHeight: 512,
		DefaultWidth: 550,
		MaxWidth: 550,
		DefaultHistory: 0,
		MaxHistory: 0,
		DefaultForecast: 12,
		MaxForecast: 48,
		Skip: 0,
		Opacity: 0.8,
		RenderBackground: true,
		SourceFileTemplate: 'yyyyMMddHHmm',
		ForecastPath:
			'nl/harmonie/temperature/{runsFolder}merc/{runTimestamp}{fileTimestamp}.png',
		BackgroundPath: 'images/map-nl-550x512-mercator.gif',
		LogoPath: 'images/logo-white.png',
		ImageInterval: 60,
		IsUTC: true,
		ForecastProcessorName: 'render_temperature_nl_harmonie',
		Projection: 'merc',
	},
	[ImageRadars.RadarMapTemperature24HourForecastWebmercatorNL]: {
		DefaultHeight: 915,
		MaxHeight: 915,
		DefaultWidth: 1058,
		MaxWidth: 1058,
		DefaultHistory: 0,
		MaxHistory: 0,
		DefaultForecast: 12,
		MaxForecast: 48,
		Skip: 0,
		Opacity: 0.8,
		RenderBackground: true,
		SourceFileTemplate: 'yyyyMMddHHmm',
		ForecastPath:
			'nl/harmonie/temperature/{runsFolder}webm/{runTimestamp}{fileTimestamp}.png',
		BackgroundPath: 'images/map-nl-1058x916-webmercator.png',
		LogoPath: 'images/logo-white.png',
		ImageInterval: 60,
		IsUTC: true,
		ForecastProcessorName: 'render_temperature_nl_harmonie',
		Projection: 'webm',
	},
	[ImageRadars.RadarMapTemperature24HourForecastBE]: {
		DefaultHeight: 512,
		MaxHeight: 512,
		DefaultWidth: 550,
		MaxWidth: 550,
		DefaultHistory: 0,
		MaxHistory: 0,
		DefaultForecast: 12,
		MaxForecast: 48,
		Skip: 0,
		Opacity: 0.8,
		RenderBackground: true,
		SourceFileTemplate: 'yyyyMMddHHmm',
		ForecastPath:
			'be/harmonie/temperature/{runsFolder}merc/{runTimestamp}{fileTimestamp}.png',
		BackgroundPath: 'images/map-be-550x512-mercator.gif',
		LogoPath: 'images/logo-white.png',
		ImageInterval: 60,
		IsUTC: true,
		ForecastProcessorName: 'render_temperature_be_harmonie',
		Projection: 'merc',
	},
	[ImageRadars.RadarMapTemperature24HourForecastWebmercatorBE]: {
		DefaultHeight: 949,
		MaxHeight: 949,
		DefaultWidth: 1058,
		MaxWidth: 1058,
		DefaultHistory: 0,
		MaxHistory: 0,
		DefaultForecast: 12,
		MaxForecast: 48,
		Skip: 0,
		Opacity: 0.8,
		RenderBackground: true,
		SourceFileTemplate: 'yyyyMMddHHmm',
		ForecastPath:
			'be/harmonie/temperature/{runsFolder}webm/{runTimestamp}{fileTimestamp}.png',
		BackgroundPath: 'images/map-be-1058x949-webmercator.png',
		LogoPath: 'images/logo-white.png',
		ImageInterval: 60,
		IsUTC: true,
		ForecastProcessorName: 'render_temperature_be_harmonie',
		Projection: 'webm',
	},
	[ImageRadars.SatVisual]: {
		DefaultHeight: 615,
		MaxHeight: 615,
		DefaultWidth: 845,
		MaxWidth: 845,
		DefaultHistory: 12,
		MaxHistory: 12,
		DefaultForecast: 0,
		MaxForecast: 0,
		Skip: 0,
		RenderBackground: false,
		SourceFileTemplate: 'yyyyMMddHHmm',
		HistoryPath: 'world/satellite/europe-africa/visual/{1}/{0}.jpg',
		LogoPath: 'images/logo-white.png',
		ImageInterval: 15,
		IsUTC: true,
		Optimize: true,
		UsePngQuantizer: true,
		SubType: 'nl',
		UppercaseSubType: true,
		AllowedSubTypes: [
			'nl',
			'be',
			'eu',
			'o_eu',
			'alps',
			'ba',
			'bc',
			'ce',
			'de',
			'fr',
			'gb',
			'gr',
			'hu',
			'it',
			'ru',
			'pt',
			'pl',
			'scan',
			'sp',
			'tu',
			'af',
			'bw',
			'cg',
			'cm',
			'dz',
			'eg',
			'et',
			'ge',
			'gw',
			'is',
			'ke',
			'ly',
			'mg',
			'mo',
			'ng',
			'sd',
			'sa',
			'se',
			'so',
			'tn',
			'td',
			'tz',
			'uk',
			'wa',
			'za',
			'zm',
		],
		Archives: [
			{
				StartTimestamp: '2015-06-08T17:00:00',
				EndTimestamp: '2019-01-17T11:15:00',
				ArchivePath: 'satellite-visual/',
				ArchiveFileTemplate: 'NL-{0}.jpg',
				ArchiveFileDateFormat: 'yyyyMMddHHmm',
				MimeType: 'image/jpg',
				IsUTC: true,
				ISLegacy: true,
			},
			{
				StartTimestamp: '2019-02-05T10:30:00',
				EndTimestamp: '2100-12-31T00:00:00',
				ArchivePath: 'world/satellite/europe-africa/visual/NL/',
				ArchiveFileTemplate: '{0}.jpg',
				ArchiveFileDateFormat: 'yyyyMMddHHmm',
				MimeType: 'image/jpg',
				IsUTC: true,
				ISLegacy: false,
			},
		],
	},
	[ImageLiteRadars.SatMapVisual15m]: {
		DefaultHeight: 615,
		MaxHeight: 615,
		DefaultWidth: 845,
		MaxWidth: 845,
		DefaultHistory: 12,
		MaxHistory: 12,
		DefaultForecast: 0,
		MaxForecast: 0,
		Skip: 0,
		RenderBackground: false,
		SourceFileTemplate: 'yyyyMMddHHmm',
		HistoryPath: 'world/satellite/europe-africa/visual/{1}/{0}.jpg',
		LogoPath: 'images/logo-white.png',
		ImageInterval: 15,
		IsUTC: true,
		Optimize: true,
		UsePngQuantizer: true,
		SubType: 'be',
		UppercaseSubType: true,
		AllowedSubTypes: [
			'nl',
			'be',
			'eu',
			'o_eu',
			'alps',
			'ba',
			'bc',
			'ce',
			'de',
			'fr',
			'gb',
			'gr',
			'hu',
			'it',
			'ru',
			'pt',
			'pl',
			'scan',
			'sp',
			'tu',
			'af',
			'bw',
			'cg',
			'cm',
			'dz',
			'eg',
			'et',
			'ge',
			'gw',
			'is',
			'ke',
			'ly',
			'mg',
			'mo',
			'ng',
			'sd',
			'sa',
			'se',
			'so',
			'tn',
			'td',
			'tz',
			'uk',
			'wa',
			'za',
			'zm',
		],
		Archives: [
			{
				StartTimestamp: '2015-06-08T17:00:00',
				EndTimestamp: '2019-01-17T11:15:00',
				ArchivePath: 'satellite-visual/',
				ArchiveFileTemplate: 'NL-{0}.jpg',
				ArchiveFileDateFormat: 'yyyyMMddHHmm',
				MimeType: 'image/jpg',
				IsUTC: true,
				ISLegacy: true,
			},
			{
				StartTimestamp: '2019-02-05T10:30:00',
				EndTimestamp: '2100-12-31T00:00:00',
				ArchivePath: 'world/satellite/europe-africa/visual/NL/',
				ArchiveFileTemplate: '{0}.jpg',
				ArchiveFileDateFormat: 'yyyyMMddHHmm',
				MimeType: 'image/jpg',
				IsUTC: true,
				ISLegacy: false,
			},
		],
	},
	[ImageLiteRadars.SatMapVisualLightning15m]: {
		DefaultHeight: 582,
		MaxHeight: 582,
		DefaultWidth: 800,
		MaxWidth: 800,
		DefaultHistory: 12,
		MaxHistory: 12,
		DefaultForecast: 0,
		MaxForecast: 0,
		Skip: 0,
		RenderBackground: false,
		RenderText: false,
		SourceFileTemplate: 'yyyyMMddHHmm',
		HistoryPath:
			'world/satellite/europe-africa/lightning/visual/{1}/{0}.png',
		LogoPath: 'images/logo-white.png',
		ImageInterval: 15,
		IsUTC: true,
		Optimize: true,
		UsePngQuantizer: true,
		SubType: 'nl',
		UppercaseSubType: true,
		AllowedSubTypes: [
			'nl',
			'be',
			'eu',
			'o_eu',
			'alps',
			'ba',
			'bc',
			'ce',
			'de',
			'fr',
			'gb',
			'gr',
			'hu',
			'it',
			'ru',
			'pt',
			'pl',
			'scan',
			'sp',
			'tu',
			'af',
			'bw',
			'cg',
			'cm',
			'dz',
			'eg',
			'et',
			'ge',
			'gw',
			'is',
			'ke',
			'ly',
			'mg',
			'mo',
			'ng',
			'sd',
			'sa',
			'se',
			'so',
			'tn',
			'td',
			'tz',
			'uk',
			'wa',
			'za',
			'zm',
		],
	},
	[ImageLiteRadars.SatMapInfrared15m]: {
		DefaultHeight: 512,
		MaxHeight: 512,
		DefaultWidth: 550,
		MaxWidth: 550,
		DefaultHistory: 12,
		MaxHistory: 12,
		DefaultForecast: 0,
		MaxForecast: 0,
		Skip: 0,
		RenderBackground: false,
		SourceFileTemplate: 'yyyyMMddHHmm',
		HistoryPath: 'world/satellite/europe-africa/infrared/{1}/{0}.jpg',
		LogoPath: 'images/logo-white.png',
		ImageInterval: 15,
		IsUTC: true,
		Optimize: true,
		UsePngQuantizer: true,
		SubType: 'nl',
		UppercaseSubType: true,
		AllowedSubTypes: [
			'nl',
			'be',
			'eu',
			'o_eu',
			'alps',
			'ba',
			'bc',
			'ce',
			'de',
			'fr',
			'gb',
			'gr',
			'hu',
			'it',
			'ru',
			'pt',
			'pl',
			'scan',
			'sp',
			'tu',
			'af',
			'bw',
			'cg',
			'cm',
			'dz',
			'eg',
			'et',
			'ge',
			'gw',
			'is',
			'ke',
			'ly',
			'mg',
			'mo',
			'ng',
			'sd',
			'sa',
			'se',
			'so',
			'tn',
			'td',
			'tz',
			'uk',
			'wa',
			'za',
			'zm',
		],
	},
	[ImageLiteRadars.SatMapInfraredLightning15m]: {
		DefaultHeight: 582,
		MaxHeight: 582,
		DefaultWidth: 800,
		MaxWidth: 800,
		DefaultHistory: 12,
		MaxHistory: 12,
		DefaultForecast: 0,
		MaxForecast: 0,
		Skip: 0,
		RenderBackground: false,
		RenderText: false,
		SourceFileTemplate: 'yyyyMMddHHmm',
		HistoryPath:
			'world/satellite/europe-africa/lightning/infrared/{1}/{0}.png',
		LogoPath: 'images/logo-white.png',
		ImageInterval: 15,
		IsUTC: true,
		Optimize: true,
		UsePngQuantizer: true,
		SubType: 'nl',
		UppercaseSubType: true,
		AllowedSubTypes: [
			'nl',
			'be',
			'eu',
			'o_eu',
			'alps',
			'ba',
			'bc',
			'ce',
			'de',
			'fr',
			'gb',
			'gr',
			'hu',
			'it',
			'ru',
			'pt',
			'pl',
			'scan',
			'sp',
			'tu',
			'af',
			'bw',
			'cg',
			'cm',
			'dz',
			'eg',
			'et',
			'ge',
			'gw',
			'is',
			'ke',
			'ly',
			'mg',
			'mo',
			'ng',
			'sd',
			'sa',
			'se',
			'so',
			'tn',
			'td',
			'tz',
			'uk',
			'wa',
			'za',
			'zm',
		],
	},
	[ImageLiteRadars.SatMapCombined15m]: {
		DefaultHeight: 512,
		MaxHeight: 615,
		DefaultWidth: 550,
		MaxWidth: 845,
		DefaultHistory: 12,
		MaxHistory: 12,
		DefaultForecast: 0,
		MaxForecast: 0,
		Skip: 0,
		RenderBackground: false,
		SourceFileTemplate: 'yyyyMMddHHmm',
		HistoryPath: 'world/satellite/europe-africa/combined/{1}/{0}.jpg',
		LogoPath: 'images/logo-white.png',
		ImageInterval: 15,
		IsUTC: true,
		Optimize: true,
		UsePngQuantizer: false,
		SubType: 'nl',
		UppercaseSubType: true,
		AllowedSubTypes: ['nl', 'eu', 'be'],
	},
	[ImageRadars.SatRainCombined]: {
		DefaultHeight: 1922,
		MaxHeight: 1922,
		DefaultWidth: 1447,
		MaxWidth: 1447,
		DefaultHistory: 4,
		MaxHistory: 12,
		DefaultForecast: 0,
		MaxForecast: 0,
		Skip: 1,
		RenderBackground: false,
		RenderText: false,
		SourceFileTemplate: 'yyyyMMddHHmm',
		HistoryPath: 'world/rain/combined/current/webm/{0}.png',
		LogoPath: 'images/logo-white.png',
		ImageInterval: 15,
		IsUTC: true,
		Optimize: true,
		UsePngQuantizer: false,
	},
	[ImageRadars.SatAsiaOceania]: {
		DefaultHeight: 1375,
		MaxHeight: 1375,
		DefaultWidth: 1375,
		MaxWidth: 1375,
		DefaultHistory: 12,
		MaxHistory: 24,
		DefaultForecast: 0,
		MaxForecast: 0,
		Skip: 0,
		RenderBackground: false,
		SourceFileTemplate: 'yyyyMMddHHmm',
		HistoryPath: 'world/satellite/asia-oceania/{1}/{0}.jpg',
		LogoPath: 'images/logo-white.png',
		ImageInterval: 30,
		IsUTC: true,
		Optimize: true,
		UsePngQuantizer: true,
		SubType: 'visible',
		UppercaseSubType: false,
		AllowedSubTypes: ['infrared', 'visible'],
	},
	[ImageRadars.SatNorthAmerica]: {
		DefaultHeight: 759,
		MaxHeight: 1200,
		DefaultWidth: 759,
		MaxWidth: 1200,
		DefaultHistory: 12,
		MaxHistory: 24,
		DefaultForecast: 0,
		MaxForecast: 0,
		Skip: 0,
		RenderBackground: false,
		SourceFileTemplate: 'yyyyMMddHHmm',
		HistoryPath: 'world/satellite/north-america/{1}/{0}.png',
		LogoPath: 'images/logo-white.png',
		ImageInterval: 120,
		IsUTC: true,
		Optimize: true,
		UsePngQuantizer: true,
		SubType: 'visible',
		UppercaseSubType: false,
		AllowedSubTypes: ['infrared', 'visible'],
	},
	[ImageRadars.SatSouthAmerica]: {
		DefaultHeight: 900,
		MaxHeight: 700,
		DefaultWidth: 900,
		MaxWidth: 700,
		DefaultHistory: 12,
		MaxHistory: 24,
		DefaultForecast: 0,
		MaxForecast: 0,
		Skip: 0,
		RenderBackground: false,
		SourceFileTemplate: 'yyyyMMddHHmm',
		HistoryPath: 'world/satellite/south-america/{1}/{0}.png',
		LogoPath: 'images/logo-white.png',
		ImageInterval: 180,
		IsUTC: true,
		Optimize: true,
		UsePngQuantizer: true,
		SubType: 'visible',
		UppercaseSubType: false,
		AllowedSubTypes: ['infrared', 'visible'],
	},
	[ImageRadars.WeatherMapAirQualityOzoneNL]: {
		DefaultHeight: 988,
		MaxHeight: 2964,
		DefaultWidth: 820,
		MaxWidth: 2460,
		DefaultHistory: 6,
		MaxHistory: 24,
		DefaultForecast: 0,
		MaxForecast: 0,
		Skip: 0,
		Opacity: 1,
		RenderBackground: false,
		FontColor: '#000066',
		FontShadowColor: '#ffffff',
		SourceFileTemplate: 'yyyyMMddHHmm',
		HistoryPath: 'nl/weathermaps/Ozon/default/{0}.png',
		LogoPath: 'images/logo-weathermap-color.png',
		LogoHeight: 50,
		ImageInterval: 60,
		IsUTC: false,
	},
	[ImageRadars.WeatherMapAirQualityNO2NL]: {
		DefaultHeight: 988,
		MaxHeight: 2964,
		DefaultWidth: 820,
		MaxWidth: 2460,
		DefaultHistory: 6,
		MaxHistory: 24,
		DefaultForecast: 0,
		MaxForecast: 0,
		Skip: 0,
		Opacity: 1,
		RenderBackground: false,
		FontColor: '#000066',
		FontShadowColor: '#ffffff',
		SourceFileTemplate: 'yyyyMMddHHmm',
		HistoryPath: 'nl/weathermaps/NO2/default/{0}.png',
		LogoPath: 'images/logo-weathermap-color.png',
		LogoHeight: 50,
		ImageInterval: 60,
		IsUTC: false,
	},
	[ImageRadars.WeatherMapAirQualityPM10NL]: {
		DefaultHeight: 988,
		MaxHeight: 2964,
		DefaultWidth: 820,
		MaxWidth: 2460,
		DefaultHistory: 6,
		MaxHistory: 24,
		DefaultForecast: 0,
		MaxForecast: 0,
		Skip: 0,
		Opacity: 1,
		RenderBackground: false,
		FontColor: '#000066',
		FontShadowColor: '#ffffff',
		SourceFileTemplate: 'yyyyMMddHHmm',
		HistoryPath: 'nl/weathermaps/PM10/default/{0}.png',
		LogoPath: 'images/logo-weathermap-color.png',
		LogoHeight: 50,
		ImageInterval: 60,
		IsUTC: false,
	},
	[ImageRadars.WeatherMapAirQualityLKINL]: {
		DefaultHeight: 988,
		MaxHeight: 2964,
		DefaultWidth: 820,
		MaxWidth: 2460,
		DefaultHistory: 6,
		MaxHistory: 24,
		DefaultForecast: 0,
		MaxForecast: 0,
		Skip: 0,
		Opacity: 1,
		RenderBackground: false,
		FontColor: '#000066',
		FontShadowColor: '#ffffff',
		SourceFileTemplate: 'yyyyMMddHHmm',
		HistoryPath: 'nl/weathermaps/LKI/default/{0}.png',
		LogoPath: 'images/logo-weathermap-color.png',
		LogoHeight: 50,
		ImageInterval: 60,
		IsUTC: false,
	},
	[ImageRadars.WeatherMapHumidityNL]: {
		DefaultHeight: 988,
		MaxHeight: 2964,
		DefaultWidth: 820,
		MaxWidth: 2460,
		DefaultHistory: 6,
		MaxHistory: 36,
		DefaultForecast: 0,
		MaxForecast: 0,
		Skip: 1,
		Opacity: 1,
		RenderBackground: false,
		FontColor: '#000066',
		FontShadowColor: '#ffffff',
		SourceFileTemplate: 'yyyyMMddHHmm',
		HistoryPath: 'nl/weathermaps/Humidity/default/{0}.png',
		LogoPath: 'images/logo-weathermap-color.png',
		LogoHeight: 50,
		ImageInterval: 10,
		IsUTC: false,
	},
	[ImageRadars.WeatherMapRainfallLastHourNL]: {
		DefaultHeight: 988,
		MaxHeight: 2964,
		DefaultWidth: 820,
		MaxWidth: 2460,
		DefaultHistory: 6,
		MaxHistory: 36,
		DefaultForecast: 0,
		MaxForecast: 0,
		Skip: 1,
		Opacity: 1,
		RenderBackground: false,
		FontColor: '#000066',
		FontShadowColor: '#ffffff',
		SourceFileTemplate: 'yyyyMMddHHmm',
		HistoryPath: 'nl/weathermaps/RainFallLastHour/default/{0}.png',
		LogoPath: 'images/logo-weathermap-color.png',
		LogoHeight: 50,
		ImageInterval: 10,
		IsUTC: false,
	},
	[ImageRadars.WeatherMapRainfallLast24HoursNL]: {
		DefaultHeight: 988,
		MaxHeight: 2964,
		DefaultWidth: 820,
		MaxWidth: 2460,
		DefaultHistory: 6,
		MaxHistory: 36,
		DefaultForecast: 0,
		MaxForecast: 0,
		Skip: 1,
		Opacity: 1,
		RenderBackground: false,
		FontColor: '#000066',
		FontShadowColor: '#ffffff',
		SourceFileTemplate: 'yyyyMMddHHmm',
		HistoryPath: 'nl/weathermaps/RainFallLast24Hour/default/{0}.png',
		LogoPath: 'images/logo-weathermap-color.png',
		LogoHeight: 50,
		ImageInterval: 10,
		IsUTC: false,
	},
	[ImageRadars.WeatherMapTotalRainfallForDateNL]: {
		DefaultHeight: 988,
		MaxHeight: 2964,
		DefaultWidth: 820,
		MaxWidth: 2460,
		DefaultHistory: 1,
		MaxHistory: 14,
		DefaultForecast: 0,
		MaxForecast: 0,
		Skip: 1,
		Opacity: 1,
		RenderBackground: false,
		RenderDateTimeTemplate: 'ddd dd-MM',
		FontColor: '#000066',
		FontShadowColor: '#ffffff',
		SourceFileTemplate: 'yyyyMMdd',
		HistoryPath: 'nl/weathermaps/TotalRainfallForDate/default/{0}.png',
		LogoPath: 'images/logo-weathermap-color.png',
		LogoHeight: 50,
		ImageInterval: 1440,
		IsUTC: false,
	},
	[ImageRadars.WeatherMapSunshineNL]: {
		DefaultHeight: 988,
		MaxHeight: 2964,
		DefaultWidth: 820,
		MaxWidth: 2460,
		DefaultHistory: 6,
		MaxHistory: 36,
		DefaultForecast: 0,
		MaxForecast: 0,
		Skip: 1,
		Opacity: 1,
		RenderBackground: false,
		FontColor: '#000066',
		FontShadowColor: '#ffffff',
		SourceFileTemplate: 'yyyyMMddHHmm',
		HistoryPath: 'nl/weathermaps/Sunshinehours/default/{0}.png',
		LogoPath: 'images/logo-weathermap-color.png',
		LogoHeight: 50,
		ImageInterval: 10,
		IsUTC: false,
	},
	[ImageRadars.WeatherMapTemperatureFeelNL]: {
		DefaultHeight: 988,
		MaxHeight: 2964,
		DefaultWidth: 820,
		MaxWidth: 2460,
		DefaultHistory: 6,
		MaxHistory: 36,
		DefaultForecast: 0,
		MaxForecast: 0,
		Skip: 1,
		Opacity: 1,
		RenderBackground: false,
		FontColor: '#000066',
		FontShadowColor: '#ffffff',
		SourceFileTemplate: 'yyyyMMddHHmm',
		HistoryPath: 'nl/weathermaps/Feeltemperature/default/{0}.png',
		LogoPath: 'images/logo-weathermap-color.png',
		LogoHeight: 50,
		ImageInterval: 10,
		IsUTC: false,
	},
	[ImageRadars.WeatherMapTemperatureGroundNL]: {
		DefaultHeight: 988,
		MaxHeight: 2964,
		DefaultWidth: 820,
		MaxWidth: 2460,
		DefaultHistory: 6,
		MaxHistory: 36,
		DefaultForecast: 0,
		MaxForecast: 0,
		Skip: 1,
		Opacity: 1,
		RenderBackground: false,
		FontColor: '#000066',
		FontShadowColor: '#ffffff',
		SourceFileTemplate: 'yyyyMMddHHmm',
		HistoryPath: 'nl/weathermaps/Groundtemperature/default/{0}.png',
		LogoPath: 'images/logo-weathermap-color.png',
		LogoHeight: 50,
		ImageInterval: 10,
		IsUTC: false,
	},
	[ImageRadars.WeatherMapTemperatureGroundMinNL]: {
		DefaultHeight: 988,
		MaxHeight: 2964,
		DefaultWidth: 820,
		MaxWidth: 2460,
		DefaultHistory: 6,
		MaxHistory: 36,
		DefaultForecast: 0,
		MaxForecast: 0,
		Skip: 1,
		Opacity: 1,
		RenderBackground: false,
		FontColor: '#000066',
		FontShadowColor: '#ffffff',
		SourceFileTemplate: 'yyyyMMddHHmm',
		HistoryPath: 'nl/weathermaps/Mingroundtemperature/default/{0}.png',
		LogoPath: 'images/logo-weathermap-color.png',
		LogoHeight: 50,
		ImageInterval: 10,
		IsUTC: false,
	},
	[ImageRadars.WeatherMapTemperatureMaxNL]: {
		DefaultHeight: 988,
		MaxHeight: 2964,
		DefaultWidth: 820,
		MaxWidth: 2460,
		DefaultHistory: 6,
		MaxHistory: 36,
		DefaultForecast: 0,
		MaxForecast: 0,
		Skip: 1,
		Opacity: 1,
		RenderBackground: false,
		FontColor: '#000066',
		FontShadowColor: '#ffffff',
		SourceFileTemplate: 'yyyyMMddHHmm',
		HistoryPath: 'nl/weathermaps/Maxtemperature/default/{0}.png',
		LogoPath: 'images/logo-weathermap-color.png',
		LogoHeight: 50,
		ImageInterval: 10,
		IsUTC: false,
	},
	[ImageRadars.WeatherMapTemperatureMinNL]: {
		DefaultHeight: 988,
		MaxHeight: 2964,
		DefaultWidth: 820,
		MaxWidth: 2460,
		DefaultHistory: 6,
		MaxHistory: 36,
		DefaultForecast: 0,
		MaxForecast: 0,
		Skip: 1,
		Opacity: 1,
		RenderBackground: false,
		FontColor: '#000066',
		FontShadowColor: '#ffffff',
		SourceFileTemplate: 'yyyyMMddHHmm',
		HistoryPath: 'nl/weathermaps/Mintemperature/default/{0}.png',
		LogoPath: 'images/logo-weathermap-color.png',
		LogoHeight: 50,
		ImageInterval: 10,
		IsUTC: false,
	},
	[ImageRadars.WeatherMapTemperatureActualNL]: {
		DefaultHeight: 988,
		MaxHeight: 2964,
		DefaultWidth: 820,
		MaxWidth: 2460,
		DefaultHistory: 6,
		MaxHistory: 36,
		DefaultForecast: 0,
		MaxForecast: 0,
		Skip: 1,
		Opacity: 1,
		RenderBackground: false,
		FontColor: '#000066',
		FontShadowColor: '#ffffff',
		SourceFileTemplate: 'yyyyMMddHHmm',
		HistoryPath: 'nl/weathermaps/Temperature/default/{0}.png',
		LogoPath: 'images/logo-weathermap-color.png',
		LogoHeight: 50,
		ImageInterval: 10,
		IsUTC: false,
	},
	[ImageRadars.WeatherMapVisibilityNL]: {
		DefaultHeight: 988,
		MaxHeight: 2964,
		DefaultWidth: 820,
		MaxWidth: 2460,
		DefaultHistory: 6,
		MaxHistory: 36,
		DefaultForecast: 0,
		MaxForecast: 0,
		Skip: 1,
		Opacity: 1,
		RenderBackground: false,
		FontColor: '#000066',
		FontShadowColor: '#ffffff',
		SourceFileTemplate: 'yyyyMMddHHmm',
		HistoryPath: 'nl/weathermaps/Visibility/default/{0}.png',
		LogoPath: 'images/logo-weathermap-color.png',
		LogoHeight: 50,
		ImageInterval: 10,
		IsUTC: false,
	},
	[ImageRadars.WeatherMapWindNL]: {
		DefaultHeight: 988,
		MaxHeight: 2964,
		DefaultWidth: 820,
		MaxWidth: 2460,
		DefaultHistory: 6,
		MaxHistory: 36,
		DefaultForecast: 0,
		MaxForecast: 0,
		Skip: 1,
		Opacity: 1,
		RenderBackground: false,
		FontColor: '#000066',
		FontShadowColor: '#ffffff',
		SourceFileTemplate: 'yyyyMMddHHmm',
		HistoryPath: 'nl/weathermaps/WindspeedBft/default/{0}.png',
		LogoPath: 'images/logo-weathermap-color.png',
		LogoHeight: 50,
		ImageInterval: 10,
		IsUTC: false,
	},
	[ImageRadars.WeatherMapWindMaxNL]: {
		DefaultHeight: 988,
		MaxHeight: 2964,
		DefaultWidth: 820,
		MaxWidth: 2460,
		DefaultHistory: 6,
		MaxHistory: 36,
		DefaultForecast: 0,
		MaxForecast: 0,
		Skip: 1,
		Opacity: 1,
		RenderBackground: false,
		FontColor: '#000066',
		FontShadowColor: '#ffffff',
		SourceFileTemplate: 'yyyyMMddHHmm',
		HistoryPath: 'nl/weathermaps/MaxWindspeedBft/default/{0}.png',
		LogoPath: 'images/logo-weathermap-color.png',
		LogoHeight: 50,
		ImageInterval: 10,
		IsUTC: false,
	},
	[ImageRadars.WeatherMapWindGustsNL]: {
		DefaultHeight: 988,
		MaxHeight: 2964,
		DefaultWidth: 820,
		MaxWidth: 2460,
		DefaultHistory: 6,
		MaxHistory: 36,
		DefaultForecast: 0,
		MaxForecast: 0,
		Skip: 1,
		Opacity: 1,
		RenderBackground: false,
		FontColor: '#000066',
		FontShadowColor: '#ffffff',
		SourceFileTemplate: 'yyyyMMddHHmm',
		HistoryPath: 'nl/weathermaps/Windgusts/default/{0}.png',
		LogoPath: 'images/logo-weathermap-color.png',
		LogoHeight: 50,
		ImageInterval: 10,
		IsUTC: false,
	},
	[ImageRadars.WeatherMapWindGustsMaxNL]: {
		DefaultHeight: 988,
		MaxHeight: 2964,
		DefaultWidth: 820,
		MaxWidth: 2460,
		DefaultHistory: 6,
		MaxHistory: 36,
		DefaultForecast: 0,
		MaxForecast: 0,
		Skip: 1,
		Opacity: 1,
		RenderBackground: false,
		FontColor: '#000066',
		FontShadowColor: '#ffffff',
		SourceFileTemplate: 'yyyyMMddHHmm',
		HistoryPath: 'nl/weathermaps/Maxwindgusts/default/{0}.png',
		LogoPath: 'images/logo-weathermap-color.png',
		LogoHeight: 50,
		ImageInterval: 10,
		IsUTC: false,
	},
	[ImageRadars.WeatherMapBBQRadarNL]: {
		DefaultHeight: 988,
		MaxHeight: 2964,
		DefaultWidth: 820,
		MaxWidth: 2460,
		DefaultHistory: 0,
		MaxHistory: 0,
		DefaultForecast: 5,
		MaxForecast: 5,
		Skip: 0,
		Opacity: 1,
		RenderBackground: false,
		FontColor: '#000066',
		FontShadowColor: '#ffffff',
		SourceFileTemplate: 'yyyyMMdd',
		RenderDateTimeTemplate: 'ddd dd-MM',
		IncludeActualForForecasts: true,
		ForecastPath: 'nl/weathermaps/bbqradar/default/{fileTimestamp}.png',
		LogoPath: 'images/logo-weathermap-color.png',
		LogoHeight: 50,
		ImageInterval: 1440,
		IsUTC: false,
	},
	[ImageRadars.WeatherMapBBQRadarBE]: {
		DefaultHeight: 844,
		MaxHeight: 844,
		DefaultWidth: 920,
		MaxWidth: 920,
		DefaultHistory: 0,
		MaxHistory: 0,
		DefaultForecast: 5,
		MaxForecast: 5,
		Skip: 0,
		Opacity: 1,
		RenderBackground: false,
		FontColor: '#000066',
		FontShadowColor: '#ffffff',
		SourceFileTemplate: 'yyyyMMdd',
		RenderDateTimeTemplate: 'ddd dd-MM',
		IncludeActualForForecasts: true,
		ForecastPath: 'be/weathermaps/bbqradar/default/{fileTimestamp}.png',
		LogoPath: 'images/logo-weathermap-color.png',
		LogoHeight: 50,
		ImageInterval: 1440,
		IsUTC: false,
	},
	[ImageRadars.WeatherMapMosquitoRadarNL]: {
		DefaultHeight: 988,
		MaxHeight: 2964,
		DefaultWidth: 820,
		MaxWidth: 2460,
		DefaultHistory: 0,
		MaxHistory: 0,
		DefaultForecast: 5,
		MaxForecast: 5,
		Skip: 0,
		Opacity: 1,
		RenderBackground: false,
		FontColor: '#000066',
		FontShadowColor: '#ffffff',
		SourceFileTemplate: 'yyyyMMdd',
		RenderDateTimeTemplate: 'ddd dd-MM',
		IncludeActualForForecasts: true,
		ForecastPath:
			'nl/weathermaps/mosquitoradar/default/{fileTimestamp}.png',
		LogoPath: 'images/logo-weathermap-color.png',
		LogoHeight: 50,
		ImageInterval: 1440,
		IsUTC: false,
	},
	[ImageRadars.WeatherMapPollenRadarNL]: {
		DefaultHeight: 988,
		MaxHeight: 2964,
		DefaultWidth: 820,
		MaxWidth: 2460,
		DefaultHistory: 0,
		MaxHistory: 0,
		DefaultForecast: 5,
		MaxForecast: 5,
		Skip: 0,
		Opacity: 1,
		RenderBackground: false,
		FontColor: '#000066',
		FontShadowColor: '#ffffff',
		SourceFileTemplate: 'yyyyMMdd',
		RenderDateTimeTemplate: 'ddd dd-MM',
		IncludeActualForForecasts: true,
		ForecastPath: 'nl/weathermaps/pollenradar/default/{fileTimestamp}.png',
		LogoPath: 'images/logo-weathermap-color.png',
		LogoHeight: 50,
		ImageInterval: 1440,
		IsUTC: false,
	},
	[ImageRadars.WeatherMapPollenRadarHourlyNL]: {
		DefaultHeight: 988,
		MaxHeight: 2964,
		DefaultWidth: 820,
		MaxWidth: 2460,
		DefaultHistory: 0,
		MaxHistory: 0,
		DefaultForecast: 24,
		MaxForecast: 24,
		Skip: 0,
		Opacity: 1,
		RenderBackground: false,
		FontColor: '#000066',
		FontShadowColor: '#ffffff',
		SourceFileTemplate: 'yyyyMMddHHmm',
		RenderDateTimeTemplate: 'ddd HH:mm',
		IncludeActualForForecasts: true,
		ForecastPath:
			'nl/weathermaps/pollenradarhourly/default/{fileTimestamp}.png',
		LogoPath: 'images/logo-weathermap-color.png',
		LogoHeight: 50,
		ImageInterval: 60,
		IsUTC: false,
	},
	[ImageRadars.WeatherMapUVIndexNL]: {
		DefaultHeight: 988,
		MaxHeight: 2964,
		DefaultWidth: 820,
		MaxWidth: 2460,
		DefaultHistory: 0,
		MaxHistory: 0,
		DefaultForecast: 5,
		MaxForecast: 5,
		Skip: 0,
		Opacity: 1,
		RenderBackground: false,
		FontColor: '#000066',
		FontShadowColor: '#ffffff',
		SourceFileTemplate: 'yyyyMMdd',
		RenderDateTimeTemplate: 'ddd dd-MM',
		IncludeActualForForecasts: true,
		ForecastPath: 'nl/weathermaps/uvindex/default/{fileTimestamp}.png',
		LogoPath: 'images/logo-weathermap-color.png',
		LogoHeight: 50,
		ImageInterval: 1440,
		IsUTC: false,
	},
	[ImageRadars.WeatherMapMosquitoRadarBE]: {
		DefaultHeight: 844,
		MaxHeight: 844,
		DefaultWidth: 920,
		MaxWidth: 920,
		DefaultHistory: 0,
		MaxHistory: 0,
		DefaultForecast: 5,
		MaxForecast: 5,
		Skip: 0,
		Opacity: 1,
		RenderBackground: false,
		FontColor: '#000066',
		FontShadowColor: '#ffffff',
		SourceFileTemplate: 'yyyyMMdd',
		RenderDateTimeTemplate: 'ddd dd-MM',
		IncludeActualForForecasts: true,
		ForecastPath:
			'be/weathermaps/mosquitoradar/default/{fileTimestamp}.png',
		LogoPath: 'images/logo-weathermap-color.png',
		LogoHeight: 50,
		ImageInterval: 1440,
		IsUTC: false,
	},
	[ImageRadars.WeatherMapPollenRadarBE]: {
		DefaultHeight: 844,
		MaxHeight: 844,
		DefaultWidth: 920,
		MaxWidth: 920,
		DefaultHistory: 0,
		MaxHistory: 0,
		DefaultForecast: 5,
		MaxForecast: 5,
		Skip: 0,
		Opacity: 1,
		RenderBackground: false,
		FontColor: '#000066',
		FontShadowColor: '#ffffff',
		SourceFileTemplate: 'yyyyMMdd',
		RenderDateTimeTemplate: 'ddd dd-MM',
		IncludeActualForForecasts: true,
		ForecastPath: 'be/weathermaps/pollenradar/default/{fileTimestamp}.png',
		LogoPath: 'images/logo-weathermap-color.png',
		LogoHeight: 50,
		ImageInterval: 1440,
		IsUTC: false,
	},
	[ImageRadars.WeatherMapPollenRadarHourlyBE]: {
		DefaultHeight: 844,
		MaxHeight: 844,
		DefaultWidth: 920,
		MaxWidth: 920,
		DefaultHistory: 0,
		MaxHistory: 0,
		DefaultForecast: 24,
		MaxForecast: 24,
		Skip: 0,
		Opacity: 1,
		RenderBackground: false,
		FontColor: '#000066',
		FontShadowColor: '#ffffff',
		SourceFileTemplate: 'yyyyMMddHHmm',
		RenderDateTimeTemplate: 'ddd HH:mm',
		IncludeActualForForecasts: true,
		ForecastPath:
			'be/weathermaps/pollenradarhourly/default/{fileTimestamp}.png',
		LogoPath: 'images/logo-weathermap-color.png',
		LogoHeight: 50,
		ImageInterval: 60,
		IsUTC: false,
	},
	[ImageRadars.WeatherMapUVIndexBE]: {
		DefaultHeight: 844,
		MaxHeight: 844,
		DefaultWidth: 920,
		MaxWidth: 920,
		DefaultHistory: 0,
		MaxHistory: 0,
		DefaultForecast: 5,
		MaxForecast: 5,
		Skip: 0,
		Opacity: 1,
		RenderBackground: false,
		FontColor: '#000066',
		FontShadowColor: '#ffffff',
		SourceFileTemplate: 'yyyyMMdd',
		RenderDateTimeTemplate: 'ddd dd-MM',
		IncludeActualForForecasts: true,
		ForecastPath: 'be/weathermaps/uvindexmap/default/{fileTimestamp}.png',
		LogoPath: 'images/logo-weathermap-color.png',
		LogoHeight: 50,
		ImageInterval: 1440,
		IsUTC: false,
	},
	[ImageLiteRadars.RadarMapRain5mNL]: {
		DefaultHeight: 915,
		MaxHeight: 915,
		DefaultWidth: 1058,
		MaxWidth: 1058,
		DefaultHistory: 0,
		MaxHistory: 12,
		DefaultForecast: 12,
		MaxForecast: 36,
		Skip: 1,
		Opacity: 0.8,
		RenderBackground: false,
		RenderText: false,
		BackgroundPath: 'images/map-nl-1058x916-webmercator.png',
		SourceFileTemplate: 'yyyyMMddHHmm',
		HistoryPath: 'nl/rain/current/webm/{0}.png',
		ForecastPath:
			'nl/rain/forecast/{runsFolder}webm/{runTimestamp}{fileTimestamp}.png',
		OrangeBackgroundPath: 'images/map-nl-1820x1575-webmercator.orange.png',
		LogoPath: 'images/logo-white.png',
		UsePngQuantizer: false,
		ImageInterval: 5,
		IsUTC: true,
		HistoryProcessorName: 'render_rain_nl',
		ForecastProcessorName: 'render_rain_nl',
		Projection: 'webm',
	},
	[ImageLiteRadars.RadarMapRain5mBE]: {
		DefaultHeight: 949,
		MaxHeight: 949,
		DefaultWidth: 1058,
		MaxWidth: 1058,
		DefaultHistory: 0,
		MaxHistory: 12,
		DefaultForecast: 12,
		MaxForecast: 36,
		Skip: 1,
		Opacity: 0.8,
		RenderBackground: false,
		RenderText: false,
		BackgroundPath: 'images/map-be-1058x949-webmercator.png',
		SourceFileTemplate: 'yyyyMMddHHmm',
		HistoryPath: 'be/rain/current/webm/{0}.png',
		ForecastPath:
			'be/rain/forecast/{runsFolder}webm/{runTimestamp}{fileTimestamp}.png',
		LogoPath: 'images/logo-white.png',
		UsePngQuantizer: false,
		ImageInterval: 5,
		IsUTC: true,
		HistoryProcessorName: 'render_rain_be',
		ForecastProcessorName: 'render_rain_be',
		Projection: 'webm',
	},
	[ImageRadars.RadarMapRain15mNWEU]: {
		DefaultHeight: 1099,
		MaxHeight: 1099,
		DefaultWidth: 1303,
		MaxWidth: 1303,
		DefaultHistory: 3,
		MaxHistory: 12,
		DefaultForecast: 12,
		MaxForecast: 12,
		Skip: 1,
		Opacity: 1,
		RenderBackground: false,
		RenderText: false,
		SourceFileTemplate: 'yyyyMMddHHmm',
		HistoryPath: 'eu/rain/current/webm/{0}.png',
		ForecastPath:
			'eu/rain/forecast/{runsFolder}webm/{runTimestamp}{fileTimestamp}.png',
		LogoPath: 'images/logo-white.png',
		UsePngQuantizer: false,
		ImageInterval: 15,
		IsUTC: true,
		HistoryProcessorName: 'render_rain_eu',
		ForecastProcessorName: 'render_rain_eu',
		Projection: 'webm',
	},
	[ImageRadars.RadarMapRainCombinedWebmercatorEU]: {
		DefaultHeight: 652,
		MaxHeight: 652,
		DefaultWidth: 766,
		MaxWidth: 766,
		DefaultHistory: 3,
		MaxHistory: 12,
		DefaultForecast: 3,
		MaxForecast: 12,
		Skip: 1,
		Opacity: 0.8,
		RenderBackground: true,
		RenderText: false,
		SourceFileTemplate: 'yyyyMMddHHmm',
		BackgroundPath: 'images/map-eu-766x652-webmercator.png',
		HistoryPath: 'eu/raincombined/current/webm/{0}.png',
		ForecastPath:
			'eu/raincombined/forecast/{runsFolder}webm/{runTimestamp}{fileTimestamp}.png',
		LogoPath: 'images/logo-white.png',
		UsePngQuantizer: false,
		ImageInterval: 15,
		IsUTC: true,
		HistoryProcessorName: 'render_rain_eu_combined',
		ForecastProcessorName: 'render_rain_eu_combined',
		Projection: 'webm',
	},
	[ImageRadars.RadarMapDrizzleWebmercatorNL]: {
		DefaultHeight: 915,
		MaxHeight: 915,
		DefaultWidth: 1058,
		MaxWidth: 1058,
		DefaultHistory: 3,
		MaxHistory: 12,
		DefaultForecast: 3,
		MaxForecast: 36,
		Skip: 1,
		Opacity: 0.8,
		RenderBackground: false,
		RenderText: false,
		BackgroundPath: 'images/map-nl-1058x916-webmercator.png',
		SourceFileTemplate: 'yyyyMMddHHmm',
		HistoryPath: 'nl/drizzle/current/webm/{0}.png',
		ForecastPath:
			'nl/drizzle/forecast/{runsFolder}webm/{runTimestamp}{fileTimestamp}.png',
		OrangeBackgroundPath: 'images/map-nl-1820x1575-webmercator.orange.png',
		LogoPath: 'images/logo-white.png',
		UsePngQuantizer: false,
		ImageInterval: 5,
		IsUTC: true,
		HistoryProcessorName: 'render_drizzle_nl',
		ForecastProcessorName: 'render_drizzle_nl',
		Projection: 'webm',
	},
	[ImageRadars.RadarMapDrizzle5mBE]: {
		DefaultHeight: 949,
		MaxHeight: 949,
		DefaultWidth: 1058,
		MaxWidth: 1058,
		DefaultHistory: 3,
		MaxHistory: 12,
		DefaultForecast: 3,
		MaxForecast: 36,
		Skip: 1,
		Opacity: 0.8,
		RenderBackground: false,
		RenderText: false,
		BackgroundPath: 'images/map-be-1058x949-webmercator.png',
		SourceFileTemplate: 'yyyyMMddHHmm',
		HistoryPath: 'be/drizzle/current/webm/{0}.png',
		ForecastPath:
			'be/drizzle/forecast/{runsFolder}webm/{runTimestamp}{fileTimestamp}.png',
		LogoPath: 'images/logo-white.png',
		UsePngQuantizer: false,
		ImageInterval: 5,
		IsUTC: true,
		HistoryProcessorName: 'render_drizzle_be',
		ForecastProcessorName: 'render_drizzle_be',
		Projection: 'webm',
	},
	[ImageRadars.RadarMapHailWebmercatorNL]: {
		DefaultHeight: 915,
		MaxHeight: 915,
		DefaultWidth: 1058,
		MaxWidth: 1058,
		DefaultHistory: 3,
		MaxHistory: 12,
		DefaultForecast: 3,
		MaxForecast: 36,
		Skip: 1,
		Opacity: 0.8,
		RenderBackground: false,
		RenderText: false,
		BackgroundPath: 'images/map-nl-1058x916-webmercator.png',
		SourceFileTemplate: 'yyyyMMddHHmm',
		HistoryPath: 'nl/hail/current/webm/{0}.png',
		ForecastPath:
			'nl/hail/forecast/{runsFolder}webm/{runTimestamp}{fileTimestamp}.png',
		OrangeBackgroundPath: 'images/map-nl-1820x1575-webmercator.orange.png',
		LogoPath: 'images/logo-white.png',
		UsePngQuantizer: false,
		ImageInterval: 5,
		IsUTC: true,
		HistoryProcessorName: 'render_hail_nl',
		ForecastProcessorName: 'render_hail_nl',
		Projection: 'webm',
	},
	[ImageRadars.RadarMapHail5mBE]: {
		DefaultHeight: 949,
		MaxHeight: 949,
		DefaultWidth: 1058,
		MaxWidth: 1058,
		DefaultHistory: 3,
		MaxHistory: 12,
		DefaultForecast: 3,
		MaxForecast: 36,
		Skip: 1,
		Opacity: 0.8,
		RenderBackground: false,
		RenderText: false,
		BackgroundPath: 'images/map-be-1058x949-webmercator.png',
		SourceFileTemplate: 'yyyyMMddHHmm',
		HistoryPath: 'be/hail/current/webm/{0}.png',
		ForecastPath:
			'be/hail/forecast/{runsFolder}webm/{runTimestamp}{fileTimestamp}.png',
		LogoPath: 'images/logo-white.png',
		UsePngQuantizer: false,
		ImageInterval: 5,
		IsUTC: true,
		HistoryProcessorName: 'render_hail_be',
		ForecastProcessorName: 'render_hail_be',
		Projection: 'webm',
	},
	[ImageRadars.RadarMapSnowWebmercatorNL]: {
		DefaultHeight: 915,
		MaxHeight: 915,
		DefaultWidth: 1058,
		MaxWidth: 1058,
		DefaultHistory: 3,
		MaxHistory: 12,
		DefaultForecast: 3,
		MaxForecast: 36,
		Skip: 1,
		Opacity: 0.8,
		RenderBackground: false,
		RenderText: false,
		BackgroundPath: 'images/map-nl-1058x916-webmercator.png',
		SourceFileTemplate: 'yyyyMMddHHmm',
		HistoryPath: 'nl/snow/current/webm/{0}.png',
		ForecastPath:
			'nl/snow/forecast/{runsFolder}webm/{runTimestamp}{fileTimestamp}.png',
		OrangeBackgroundPath: 'images/map-nl-1820x1575-webmercator.orange.png',
		LogoPath: 'images/logo-white.png',
		UsePngQuantizer: false,
		ImageInterval: 5,
		IsUTC: true,
		HistoryProcessorName: 'render_snow_nl',
		ForecastProcessorName: 'render_snow_forecast_nl',
		Projection: 'webm',
	},
	[ImageRadars.RadarMapSnowWebmercatorBE]: {
		DefaultHeight: 949,
		MaxHeight: 949,
		DefaultWidth: 1058,
		MaxWidth: 1058,
		DefaultHistory: 3,
		MaxHistory: 12,
		DefaultForecast: 3,
		MaxForecast: 36,
		Skip: 1,
		Opacity: 0.8,
		RenderBackground: false,
		RenderText: false,
		BackgroundPath: 'images/map-be-1058x949-webmercator.png',
		SourceFileTemplate: 'yyyyMMddHHmm',
		HistoryPath: 'be/snow/current/webm/{0}.png',
		ForecastPath:
			'be/snow/forecast/{runsFolder}webm/{runTimestamp}{fileTimestamp}.png',
		LogoPath: 'images/logo-white.png',
		UsePngQuantizer: false,
		ImageInterval: 5,
		IsUTC: true,
		HistoryProcessorName: 'render_snow_be',
		ForecastProcessorName: 'render_snow_be',
		Projection: 'webm',
	},
	[ImageRadars.RadarMapLightningWebmercatorNL]: {
		DefaultHeight: 915,
		MaxHeight: 915,
		DefaultWidth: 1058,
		MaxWidth: 1058,
		DefaultHistory: 3,
		MaxHistory: 12,
		DefaultForecast: 3,
		MaxForecast: 36,
		Skip: 1,
		Opacity: 0.8,
		RenderBackground: false,
		RenderText: false,
		BackgroundPath: 'images/map-nl-1058x916-webmercator.png',
		SourceFileTemplate: 'yyyyMMddHHmm',
		HistoryPath: 'nl/lightning/current/webm/{0}.png',
		ForecastPath:
			'nl/lightning/forecast/{runsFolder}webm/{runTimestamp}{fileTimestamp}.png',
		OrangeBackgroundPath: 'images/map-nl-1820x1575-webmercator.orange.png',
		LogoPath: 'images/logo-white.png',
		UsePngQuantizer: false,
		ImageInterval: 5,
		IsUTC: true,
		HistoryProcessorName: 'render_lightning_nl',
		Projection: 'webm',
	},
	[ImageRadars.RadarMapLightning5mBE]: {
		DefaultHeight: 949,
		MaxHeight: 949,
		DefaultWidth: 1058,
		MaxWidth: 1058,
		DefaultHistory: 3,
		MaxHistory: 12,
		DefaultForecast: 3,
		MaxForecast: 36,
		Skip: 1,
		Opacity: 0.8,
		RenderBackground: false,
		RenderText: false,
		BackgroundPath: 'images/map-be-1058x949-webmercator.png',
		SourceFileTemplate: 'yyyyMMddHHmm',
		HistoryPath: 'be/lightning/current/webm/{0}.png',
		ForecastPath:
			'be/lightning/forecast/{runsFolder}webm/{runTimestamp}{fileTimestamp}.png',
		LogoPath: 'images/logo-white.png',
		UsePngQuantizer: false,
		ImageInterval: 5,
		IsUTC: true,
		HistoryProcessorName: 'render_lightning_be',
		Projection: 'webm',
	},
	[ImageRadars.RadarMapSunWebmercatorNL]: {
		DefaultHeight: 953,
		MaxHeight: 953,
		DefaultWidth: 1100,
		MaxWidth: 1100,
		DefaultHistory: 3,
		MaxHistory: 12,
		DefaultForecast: 3,
		MaxForecast: 36,
		Skip: 1,
		Opacity: 1,
		RenderBackground: false,
		RenderText: false,
		BackgroundPath: 'images/map-nl-1058x916-webmercator.png',
		SourceFileTemplate: 'yyyyMMddHHmm',
		HistoryPath: 'nl/sun/current/webm/{0}.png',
		ForecastPath:
			'nl/sun/forecast/{runsFolder}webm/{runTimestamp}{fileTimestamp}.png',
		LogoPath: 'images/logo-white.png',
		UsePngQuantizer: false,
		ImageInterval: 5,
		IsUTC: true,
		HistoryProcessorName: 'render_cloud_nl',
		ForecastProcessorName: 'render_cloud_nl',
		Projection: 'webm',
	},
	[ImageRadars.RadarMapSun5mBE]: {
		DefaultHeight: 949,
		MaxHeight: 949,
		DefaultWidth: 1058,
		MaxWidth: 1058,
		DefaultHistory: 3,
		MaxHistory: 12,
		DefaultForecast: 3,
		MaxForecast: 36,
		Skip: 1,
		Opacity: 1,
		RenderBackground: false,
		RenderText: false,
		BackgroundPath: 'images/map-be-1058x949-webmercator.png',
		SourceFileTemplate: 'yyyyMMddHHmm',
		HistoryPath: 'be/sun/current/webm/{0}.png',
		ForecastPath:
			'be/sun/forecast/{runsFolder}webm/{runTimestamp}{fileTimestamp}.png',
		LogoPath: 'images/logo-white.png',
		UsePngQuantizer: false,
		ImageInterval: 5,
		IsUTC: true,
		HistoryProcessorName: 'render_cloud_be',
		ForecastProcessorName: 'render_cloud_be',
		Projection: 'webm',
	},
	[ImageRadars.RadarMapCloudWebmercatorNL]: {
		DefaultHeight: 953,
		MaxHeight: 953,
		DefaultWidth: 1100,
		MaxWidth: 1100,
		DefaultHistory: 3,
		MaxHistory: 12,
		DefaultForecast: 3,
		MaxForecast: 36,
		Skip: 1,
		Opacity: 1,
		RenderBackground: true,
		RenderText: false,
		BackgroundPath: 'images/map-nl-1058x916-webmercator.png',
		SourceFileTemplate: 'yyyyMMddHHmm',
		HistoryPath: 'nl/cloud/current/webm/{0}.png',
		ForecastPath:
			'nl/cloud/forecast/{runsFolder}webm/{runTimestamp}{fileTimestamp}.png',
		LogoPath: 'images/logo-white.png',
		UsePngQuantizer: false,
		ImageInterval: 5,
		IsUTC: true,
		HistoryProcessorName: 'render_cloud_nl',
		ForecastProcessorName: 'render_cloud_nl',
		Projection: 'webm',
	},
	[ImageRadars.RadarMapCloud5mBE]: {
		DefaultHeight: 949,
		MaxHeight: 949,
		DefaultWidth: 1058,
		MaxWidth: 1058,
		DefaultHistory: 3,
		MaxHistory: 12,
		DefaultForecast: 3,
		MaxForecast: 36,
		Skip: 1,
		Opacity: 1,
		RenderBackground: true,
		RenderText: false,
		BackgroundPath: 'images/map-be-1058x949-webmercator.png',
		SourceFileTemplate: 'yyyyMMddHHmm',
		HistoryPath: 'be/cloud/current/webm/{0}.png',
		ForecastPath:
			'be/cloud/forecast/{runsFolder}webm/{runTimestamp}{fileTimestamp}.png',
		LogoPath: 'images/logo-white.png',
		UsePngQuantizer: false,
		ImageInterval: 5,
		IsUTC: true,
		HistoryProcessorName: 'render_cloud_nl',
		ForecastProcessorName: 'render_cloud_nl',
		Projection: 'webm',
	},
	[ImageRadars.RadarMapCloudWebmercatorGfsEU]: {
		DefaultHeight: 949,
		MaxHeight: 949,
		DefaultWidth: 1058,
		MaxWidth: 1058,
		DefaultHistory: 3,
		MaxHistory: 12,
		DefaultForecast: 3,
		MaxForecast: 36,
		Skip: 1,
		Opacity: 1,
		RenderBackground: true,
		RenderText: false,
		BackgroundPath: 'images/map-be-1058x949-webmercator.png',
		SourceFileTemplate: 'yyyyMMddHHmm',
		HistoryPath: 'be/cloud/current/webm/{0}.png',
		ForecastPath:
			'be/cloud/forecast/{runsFolder}webm/{runTimestamp}{fileTimestamp}.png',
		LogoPath: 'images/logo-white.png',
		UsePngQuantizer: false,
		ImageInterval: 5,
		IsUTC: true,
		HistoryProcessorName: 'render_cloud_nl',
		ForecastProcessorName: 'render_cloud_nl',
		Projection: 'webm',
	},
	[ImageRadars.RadarMapCloudHeight5mNL]: {
		DefaultHeight: 820,
		MaxHeight: 1640,
		DefaultWidth: 765,
		MaxWidth: 1530,
		DefaultHistory: 1,
		MaxHistory: 36,
		DefaultForecast: 0,
		MaxForecast: 0,
		Skip: 0,
		Opacity: 1,
		RenderBackground: false,
		RenderText: false,
		SourceFileTemplate: 'yyyyMMddHHmm',
		HistoryPath: 'nl/cloudheight/current/webm/{0}.png',
		ForecastPath:
			'nl/cloudheight/forecast/{runsFolder}webm/{runTimestamp}{fileTimestamp}.png',
		BackgroundPath: 'images/map-cloudheight-1530x1640-webmercator.png',
		LogoPath: 'images/logo-white.png',
		UsePngQuantizer: false,
		ImageInterval: 5,
		IsUTC: true,
		HistoryProcessorName: 'render_cloudheight_nl',
		Projection: 'webm',
	},
	[ImageRadars.RadarMapSnowWebmercatorEU]: {
		DefaultHeight: 1100,
		MaxHeight: 1100,
		DefaultWidth: 1100,
		MaxWidth: 1100,
		DefaultHistory: 3,
		MaxHistory: 12,
		DefaultForecast: 3,
		MaxForecast: 12,
		Skip: 1,
		Opacity: 1,
		RenderBackground: false,
		RenderText: false,
		SourceFileTemplate: 'yyyyMMddHHmm',
		HistoryPath: 'eu/snow/current/webm/{0}.png',
		ForecastPath:
			'eu/snow/forecast/{runsFolder}webm/{runTimestamp}{fileTimestamp}.png',
		LogoPath: 'images/logo-white.png',
		UsePngQuantizer: false,
		ImageInterval: 15,
		IsUTC: true,
		HistoryProcessorName: 'render_snow_eu',
		ForecastProcessorName: 'render_snow_eu',
		Projection: 'webm',
	},
	[ImageLiteRadars.RadarMapCloud3hEU]: {
		DefaultHeight: 1500,
		MaxHeight: 1500,
		DefaultWidth: 1905,
		MaxWidth: 1905,
		DefaultHistory: 12,
		MaxHistory: 12,
		DefaultForecast: 12,
		MaxForecast: 54,
		Skip: 1,
		Opacity: 1,
		RenderBackground: false,
		RenderText: false,
		SourceFileTemplate: 'yyyyMMddHHmm',
		ForecastPath: 'eu/7dayforecast/cloud/webm/{fileTimestamp}.png',
		BackgroundPath: 'images/map-eu-1500x1181-webmercator.png',
		LogoPath: 'images/logo-white.png',
		UsePngQuantizer: false,
		Optimize: false,
		ImageInterval: 180,
		IsUTC: true,
		SkipSpriteRendering: true,
		ForecastProcessorName: 'render_cloud_eu_gfs',
		Projection: 'webm',
	},
	[ImageLiteRadars.RadarMapRain3hEU]: {
		DefaultHeight: 1500,
		MaxHeight: 1500,
		DefaultWidth: 1905,
		MaxWidth: 1905,
		DefaultHistory: 12,
		MaxHistory: 12,
		DefaultForecast: 12,
		MaxForecast: 54,
		Skip: 1,
		Opacity: 1,
		RenderBackground: false,
		RenderText: false,
		SourceFileTemplate: 'yyyyMMddHHmm',
		ForecastPath: 'eu/7dayforecast/rain/webm/{fileTimestamp}.png',
		BackgroundPath: 'images/map-eu-1500x1181-webmercator.png',
		LogoPath: 'images/logo-white.png',
		UsePngQuantizer: false,
		Optimize: false,
		ImageInterval: 180,
		IsUTC: true,
		SkipSpriteRendering: true,
		ForecastProcessorName: 'renderer_layers_rain_eu_gfs',
		Projection: 'webm',
	},
	[ImageLiteRadars.RadarMapTemperature3hEU]: {
		DefaultHeight: 1500,
		MaxHeight: 1500,
		DefaultWidth: 1905,
		MaxWidth: 1905,
		DefaultHistory: 12,
		MaxHistory: 12,
		DefaultForecast: 12,
		MaxForecast: 54,
		Skip: 1,
		Opacity: 1,
		RenderBackground: false,
		RenderText: false,
		SourceFileTemplate: 'yyyyMMddHHmm',
		ForecastPath: 'eu/7dayforecast/temperature/webm/{fileTimestamp}.png',
		LogoPath: 'images/logo-white.png',
		UsePngQuantizer: false,
		Optimize: true,
		ImageInterval: 180,
		IsUTC: true,
		SkipSpriteRendering: true,
		ForecastProcessorName: 'render_temperature_eu_gfs',
		Projection: 'webm',
	},
	[ImageLiteRadars.RadarMapWindPressure3hEU]: {
		DefaultHeight: 1500,
		MaxHeight: 1500,
		DefaultWidth: 1905,
		MaxWidth: 1905,
		DefaultHistory: 12,
		MaxHistory: 12,
		DefaultForecast: 12,
		MaxForecast: 54,
		Skip: 1,
		Opacity: 1,
		RenderBackground: false,
		RenderText: false,
		SourceFileTemplate: 'yyyyMMddHHmm',
		ForecastPath: 'eu/7dayforecast/wind_pressure/webm/{fileTimestamp}.png',
		LogoPath: 'images/logo-white.png',
		UsePngQuantizer: false,
		Optimize: true,
		ImageInterval: 180,
		IsUTC: true,
		SkipSpriteRendering: true,
		ForecastProcessorName: 'renderer_layers_wind_pressure_eu_gfs',
		Projection: 'webm',
	},
	[ImageLiteRadars.RadarMapLightningChance3hEU]: {
		DefaultHeight: 1500,
		MaxHeight: 1500,
		DefaultWidth: 1905,
		MaxWidth: 1905,
		DefaultHistory: 12,
		MaxHistory: 12,
		DefaultForecast: 12,
		MaxForecast: 54,
		Skip: 1,
		Opacity: 1,
		RenderBackground: false,
		RenderText: false,
		SourceFileTemplate: 'yyyyMMddHHmm',
		ForecastPath:
			'eu/7dayforecast/lightningchance/webm/{fileTimestamp}.png',
		BackgroundPath: 'images/map-eu-1500x1181-webmercator.png',
		LogoPath: 'images/logo-white.png',
		UsePngQuantizer: false,
		Optimize: true,
		ImageInterval: 180,
		IsUTC: true,
		SkipSpriteRendering: true,
		ForecastProcessorName: 'render_lightning_chance_eu_gfs',
		Projection: 'webm',
	},
	[ImageLiteRadars.RadarMapSnowDepth3hEU]: {
		DefaultHeight: 1500,
		MaxHeight: 1500,
		DefaultWidth: 1905,
		MaxWidth: 1905,
		DefaultHistory: 12,
		MaxHistory: 12,
		DefaultForecast: 12,
		MaxForecast: 54,
		Skip: 1,
		Opacity: 1,
		RenderBackground: false,
		RenderText: false,
		SourceFileTemplate: 'yyyyMMddHHmm',
		ForecastPath: 'eu/7dayforecast/snowdepth/webm/{fileTimestamp}.png',
		BackgroundPath: 'images/map-eu-1500x1181-webmercator.png',
		LogoPath: 'images/logo-white.png',
		UsePngQuantizer: false,
		Optimize: true,
		ImageInterval: 180,
		IsUTC: true,
		SkipSpriteRendering: true,
		ForecastProcessorName: 'render_snow_height_eu_gfs',
		Projection: 'webm',
	},
	[ImageRadars.AnwbMapRainNL]: {
		DefaultHeight: 765,
		MaxHeight: 765,
		DefaultWidth: 700,
		MaxWidth: 700,
		DefaultHistory: 3,
		MaxHistory: 25,
		DefaultForecast: 3,
		MaxForecast: 25,
		Skip: 1,
		Opacity: 1,
		RenderBackground: false,
		RenderText: false,
		ImageOffsetX: 37,
		ImageOffsetY: 180,
		SourceFileTemplate: 'yyyyMMddHHmm',
		HistoryPath: 'nl/rain/current/merc/{0}.png',
		ForecastPath:
			'nl/rain/forecast/{runsFolder}merc/{runTimestamp}{fileTimestamp}.png',
		LogoPath: 'images/logo-white.png',
		ImageInterval: 5,
		IsUTC: true,
		HistoryProcessorName: 'render_rain_nl',
		ForecastProcessorName: 'render_rain_nl',
		Projection: 'merc',
	},
	[ImageLiteRadars.WeatherMapFeelTemperature1hBE]: {
		DefaultHeight: 844,
		MaxHeight: 844,
		DefaultWidth: 920,
		MaxWidth: 920,
		DefaultHistory: 6,
		MaxHistory: 36,
		DefaultForecast: 0,
		MaxForecast: 0,
		Skip: 1,
		Opacity: 1,
		RenderBackground: false,
		FontColor: '#000066',
		FontShadowColor: '#ffffff',
		SourceFileTemplate: 'yyyyMMddHHmm',
		RenderDateTimeTemplate: 'ddd HH:mm',
		IncludeActualForForecasts: true,
		HistoryPath: 'be/weathermaps/Feeltemperature/default/{0}.png',
		LogoPath: 'images/logo-weathermap-color.png',
		LogoHeight: 50,
		ImageInterval: 60,
		IsUTC: false,
	},
	[ImageRadars.WeatherMapHumidityBE]: {
		DefaultHeight: 844,
		MaxHeight: 844,
		DefaultWidth: 920,
		MaxWidth: 920,
		DefaultHistory: 6,
		MaxHistory: 36,
		DefaultForecast: 0,
		MaxForecast: 0,
		Skip: 1,
		Opacity: 1,
		RenderBackground: false,
		FontColor: '#000066',
		FontShadowColor: '#ffffff',
		SourceFileTemplate: 'yyyyMMddHHmm',
		RenderDateTimeTemplate: 'ddd HH:mm',
		IncludeActualForForecasts: true,
		HistoryPath: 'be/weathermaps/Humidity/default/{0}.png',
		LogoPath: 'images/logo-weathermap-color.png',
		LogoHeight: 50,
		ImageInterval: 60,
		IsUTC: false,
	},
	[ImageLiteRadars.WeatherMapMinTemperature1hBE]: {
		DefaultHeight: 844,
		MaxHeight: 844,
		DefaultWidth: 920,
		MaxWidth: 920,
		DefaultHistory: 6,
		MaxHistory: 36,
		DefaultForecast: 0,
		MaxForecast: 0,
		Skip: 1,
		Opacity: 1,
		RenderBackground: false,
		FontColor: '#000066',
		FontShadowColor: '#ffffff',
		SourceFileTemplate: 'yyyyMMddHHmm',
		RenderDateTimeTemplate: 'ddd HH:mm',
		IncludeActualForForecasts: true,
		HistoryPath: 'be/weathermaps/Mintemperature/default/{0}.png',
		LogoPath: 'images/logo-weathermap-color.png',
		LogoHeight: 50,
		ImageInterval: 60,
		IsUTC: false,
	},
	[ImageLiteRadars.WeatherMapMaxTemperature1hBE]: {
		DefaultHeight: 844,
		MaxHeight: 844,
		DefaultWidth: 920,
		MaxWidth: 920,
		DefaultHistory: 6,
		MaxHistory: 36,
		DefaultForecast: 0,
		MaxForecast: 0,
		Skip: 1,
		Opacity: 1,
		RenderBackground: false,
		FontColor: '#000066',
		FontShadowColor: '#ffffff',
		SourceFileTemplate: 'yyyyMMddHHmm',
		RenderDateTimeTemplate: 'ddd HH:mm',
		IncludeActualForForecasts: true,
		HistoryPath: 'be/weathermaps/Maxtemperature/default/{0}.png',
		LogoPath: 'images/logo-weathermap-color.png',
		LogoHeight: 50,
		ImageInterval: 60,
		IsUTC: false,
	},
	[ImageLiteRadars.WeatherMapActualTemperature1hBE]: {
		DefaultHeight: 844,
		MaxHeight: 844,
		DefaultWidth: 920,
		MaxWidth: 920,
		DefaultHistory: 6,
		MaxHistory: 36,
		DefaultForecast: 0,
		MaxForecast: 0,
		Skip: 1,
		Opacity: 1,
		RenderBackground: false,
		FontColor: '#000066',
		FontShadowColor: '#ffffff',
		SourceFileTemplate: 'yyyyMMddHHmm',
		RenderDateTimeTemplate: 'ddd HH:mm',
		IncludeActualForForecasts: true,
		HistoryPath: 'be/weathermaps/Temperature/default/{0}.png',
		LogoPath: 'images/logo-weathermap-color.png',
		LogoHeight: 50,
		ImageInterval: 60,
		IsUTC: false,
	},
	[ImageRadars.WeatherMapWindMaxBE]: {
		DefaultHeight: 844,
		MaxHeight: 844,
		DefaultWidth: 920,
		MaxWidth: 920,
		DefaultHistory: 6,
		MaxHistory: 36,
		DefaultForecast: 0,
		MaxForecast: 0,
		Skip: 1,
		Opacity: 1,
		RenderBackground: false,
		FontColor: '#000066',
		FontShadowColor: '#ffffff',
		SourceFileTemplate: 'yyyyMMddHHmm',
		RenderDateTimeTemplate: 'ddd HH:mm',
		IncludeActualForForecasts: true,
		HistoryPath: 'be/weathermaps/MaxWindspeedBft/default/{0}.png',
		LogoPath: 'images/logo-weathermap-color.png',
		LogoHeight: 50,
		ImageInterval: 60,
		IsUTC: false,
	},
	[ImageRadars.WeatherMapWindBE]: {
		DefaultHeight: 844,
		MaxHeight: 844,
		DefaultWidth: 920,
		MaxWidth: 920,
		DefaultHistory: 6,
		MaxHistory: 36,
		DefaultForecast: 0,
		MaxForecast: 0,
		Skip: 1,
		Opacity: 1,
		RenderBackground: false,
		FontColor: '#000066',
		FontShadowColor: '#ffffff',
		SourceFileTemplate: 'yyyyMMddHHmm',
		RenderDateTimeTemplate: 'ddd HH:mm',
		IncludeActualForForecasts: true,
		HistoryPath: 'be/weathermaps/WindspeedBft/default/{0}.png',
		LogoPath: 'images/logo-weathermap-color.png',
		LogoHeight: 50,
		ImageInterval: 60,
		IsUTC: false,
	},
	[ImageRadars.WeatherMapWindGustsBE]: {
		DefaultHeight: 844,
		MaxHeight: 844,
		DefaultWidth: 920,
		MaxWidth: 920,
		DefaultHistory: 6,
		MaxHistory: 36,
		DefaultForecast: 0,
		MaxForecast: 0,
		Skip: 1,
		Opacity: 1,
		RenderBackground: false,
		FontColor: '#000066',
		FontShadowColor: '#ffffff',
		SourceFileTemplate: 'yyyyMMddHHmm',
		RenderDateTimeTemplate: 'ddd HH:mm',
		IncludeActualForForecasts: true,
		HistoryPath: 'be/weathermaps/Windgusts/default/{0}.png',
		LogoPath: 'images/logo-weathermap-color.png',
		LogoHeight: 50,
		ImageInterval: 60,
		IsUTC: false,
	},
	[ImageRadars.WeatherMapRainfallLastHourBE]: {
		DefaultHeight: 844,
		MaxHeight: 844,
		DefaultWidth: 920,
		MaxWidth: 920,
		DefaultHistory: 6,
		MaxHistory: 36,
		DefaultForecast: 0,
		MaxForecast: 0,
		Skip: 1,
		Opacity: 1,
		RenderBackground: false,
		FontColor: '#000066',
		FontShadowColor: '#ffffff',
		SourceFileTemplate: 'yyyyMMddHHmm',
		RenderDateTimeTemplate: 'ddd HH:mm',
		IncludeActualForForecasts: true,
		HistoryPath: 'be/weathermaps/RainFallLastHour/default/{0}.png',
		LogoPath: 'images/logo-weathermap-color.png',
		LogoHeight: 50,
		ImageInterval: 60,
		IsUTC: false,
	},
	[ImageRadars.WeatherMapTotalRainfallForDateBE]: {
		DefaultHeight: 844,
		MaxHeight: 844,
		DefaultWidth: 920,
		MaxWidth: 920,
		DefaultHistory: 1,
		MaxHistory: 14,
		DefaultForecast: 0,
		MaxForecast: 0,
		Skip: 1,
		Opacity: 1,
		RenderBackground: false,
		FontColor: '#000066',
		FontShadowColor: '#ffffff',
		SourceFileTemplate: 'yyyyMMdd',
		RenderDateTimeTemplate: 'ddd dd-MM',
		IncludeActualForForecasts: true,
		HistoryPath: 'be/weathermaps/TotalRainfallForDate/default/{0}.png',
		LogoPath: 'images/logo-weathermap-color.png',
		LogoHeight: 50,
		ImageInterval: 1440,
		IsUTC: false,
	},
	[ImageRadars.WeatherMapVisibilityBE]: {
		DefaultHeight: 844,
		MaxHeight: 844,
		DefaultWidth: 920,
		MaxWidth: 920,
		DefaultHistory: 6,
		MaxHistory: 36,
		DefaultForecast: 0,
		MaxForecast: 0,
		Skip: 1,
		Opacity: 1,
		RenderBackground: false,
		FontColor: '#000066',
		FontShadowColor: '#ffffff',
		SourceFileTemplate: 'yyyyMMddHHmm',
		RenderDateTimeTemplate: 'ddd HH:mm',
		IncludeActualForForecasts: true,
		HistoryPath: 'be/weathermaps/Visibility/default/{0}.png',
		LogoPath: 'images/logo-weathermap-color.png',
		LogoHeight: 50,
		ImageInterval: 60,
		IsUTC: false,
	},
	[ImageRadars.AirQualityWebmercatorNL]: {
		DefaultHeight: 915,
		MaxHeight: 915,
		DefaultWidth: 1058,
		MaxWidth: 1058,
		DefaultHistory: 0,
		MaxHistory: 0,
		DefaultForecast: 1,
		MaxForecast: 120,
		Skip: 0,
		Opacity: 1,
		RenderBackground: false,
		RenderText: false,
		BackgroundPath: 'images/map-nl-1058x916-webmercator.png',
		SourceFileTemplate: 'yyyyMMddHHmm',
		ForecastPath:
			'nl/airquality/{subType}/forecast/{runsFolder}webm/{runTimestamp}{fileTimestamp}.png',
		OrangeBackgroundPath: 'images/map-nl-1820x1575-webmercator.orange.png',
		LogoPath: 'images/logo-white.png',
		UsePngQuantizer: false,
		ImageInterval: 60,
		IsUTC: true,
		SubType: 'aqi',
		UppercaseSubType: false,
		ForecastProcessorName: 'render_airquality_nl_airportal_{subType}',
		Projection: 'webm',
		AllowedSubTypes: ['aqi', 'no2', 'o3', 'pm10', 'pm2p5'],
		SkipSpriteRendering: true,
	},
	[ImageRadars.RadarMapRainNLEightHour]: {
		DefaultHeight: 512,
		MaxHeight: 512,
		DefaultWidth: 550,
		MaxWidth: 550,
		DefaultHistory: 0,
		MaxHistory: 0,
		DefaultForecast: 1,
		MaxForecast: 32,
		Skip: 0,
		Opacity: 0.8,
		RenderBackground: true,
		SourceFileTemplate: 'yyyyMMddHHmm',
		BackgroundPath: 'images/map-nl-550x512-mercator.gif',
		OrangeBackgroundPath: 'images/map-nl-550x512-mercator.oranje.gif',
		LogoPath: 'images/logo-white.png',
		ForecastPath:
			'nl/raineighthour/forecast/{runsFolder}merc/{runTimestamp}{fileTimestamp}.png',
		ImageInterval: 15,
		IsUTC: true,
		GifDelay: 500,
		ProcessorName: 'render_rain_nl_eighthour',
		Projection: 'merc',
	},
	[ImageRadars.RadarMapRainWebmercatorNLEightHour]: {
		DefaultHeight: 915,
		MaxHeight: 915,
		DefaultWidth: 1058,
		MaxWidth: 1058,
		DefaultHistory: 0,
		MaxHistory: 0,
		DefaultForecast: 3,
		MaxForecast: 32,
		Skip: 0,
		Opacity: 0.8,
		RenderBackground: false,
		RenderText: false,
		BackgroundPath: 'images/map-nl-1058x916-webmercator.png',
		SourceFileTemplate: 'yyyyMMddHHmm',
		HistoryPath: 'nl/raineighthour/current/webm/{0}.png',
		OrangeBackgroundPath: 'images/map-nl-1820x1575-webmercator.orange.png',
		LogoPath: 'images/logo-white.png',
		ForecastPath:
			'nl/raineighthour/forecast/{runsFolder}webm/{runTimestamp}{fileTimestamp}.png',
		UsePngQuantizer: false,
		ImageInterval: 15,
		IsUTC: true,
		ProcessorName: 'render_rain_nl_eighthour',
		Projection: 'webm',
	},
	[ImageRadars.RadarMapRainBEEightHour]: {
		DefaultHeight: 512,
		MaxHeight: 512,
		DefaultWidth: 550,
		MaxWidth: 550,
		DefaultHistory: 0,
		MaxHistory: 0,
		DefaultForecast: 1,
		MaxForecast: 32,
		Skip: 0,
		Opacity: 0.8,
		RenderBackground: true,
		SourceFileTemplate: 'yyyyMMddHHmm',
		BackgroundPath: 'images/map-be-550x512-mercator.gif',
		LogoPath: 'images/logo-white.png',
		ForecastPath:
			'be/raineighthour/forecast/{runsFolder}merc/{runTimestamp}{fileTimestamp}.png',
		ImageInterval: 15,
		IsUTC: true,
		GifDelay: 500,
		ProcessorName: 'render_rain_be_eighthour',
		Projection: 'merc',
	},
	[ImageRadars.RadarMapRain15mBE]: {
		DefaultHeight: 949,
		MaxHeight: 949,
		DefaultWidth: 1058,
		MaxWidth: 1058,
		DefaultHistory: 0,
		MaxHistory: 0,
		DefaultForecast: 3,
		MaxForecast: 32,
		Skip: 0,
		Opacity: 0.8,
		RenderBackground: false,
		RenderText: false,
		BackgroundPath: 'images/map-be-1058x949-webmercator.png',
		SourceFileTemplate: 'yyyyMMddHHmm',
		HistoryPath: 'be/raineighthour/current/webm/{0}.png',
		LogoPath: 'images/logo-white.png',
		ForecastPath:
			'be/raineighthour/forecast/{runsFolder}webm/{runTimestamp}{fileTimestamp}.png',
		UsePngQuantizer: false,
		ImageInterval: 15,
		IsUTC: true,
		ProcessorName: 'render_rain_be_eighthour',
		Projection: 'webm',
	},
	[ImageRadars.RadarMapRain24HourForecastEU]: {
		DefaultHeight: 512,
		MaxHeight: 512,
		DefaultWidth: 550,
		MaxWidth: 550,
		DefaultHistory: 0,
		MaxHistory: 0,
		DefaultForecast: 12,
		MaxForecast: 48,
		Skip: 0,
		Opacity: 0.8,
		RenderBackground: true,
		SourceFileTemplate: 'yyyyMMddHHmm',
		ForecastPath:
			'eu/harmonie/rain/{runsFolder}merc/{runTimestamp}{fileTimestamp}.png',
		BackgroundPath: 'images/map-eu-550x512-mercator.png',
		LogoPath: 'images/logo-white.png',
		ImageInterval: 60,
		ForecastProcessorName: 'renderer_layers_rain_eu_harmonie',
		Projection: 'merc',
		IsUTC: true,
	},
	[ImageRadars.RadarMapSun24HourForecastEU]: {
		DefaultHeight: 512,
		MaxHeight: 512,
		DefaultWidth: 550,
		MaxWidth: 550,
		DefaultHistory: 0,
		MaxHistory: 0,
		DefaultForecast: 12,
		MaxForecast: 48,
		Skip: 0,
		Opacity: 0.8,
		RenderBackground: true,
		SourceFileTemplate: 'yyyyMMddHHmm',
		ForecastPath:
			'eu/harmonie/sun/{runsFolder}merc/{runTimestamp}{fileTimestamp}.png',
		BackgroundPath: 'images/map-eu-550x512-mercator.png',
		LogoPath: 'images/logo-white.png',
		ImageInterval: 60,
		ForecastProcessorName: 'renderer_sun_eu_harmonie',
		Projection: 'merc',
		IsUTC: true,
	},
	[ImageRadars.RadarMapCloud24HourForecastEU]: {
		DefaultHeight: 512,
		MaxHeight: 512,
		DefaultWidth: 550,
		MaxWidth: 550,
		DefaultHistory: 0,
		MaxHistory: 0,
		DefaultForecast: 12,
		MaxForecast: 48,
		Skip: 0,
		Opacity: 0.8,
		RenderBackground: true,
		SourceFileTemplate: 'yyyyMMddHHmm',
		ForecastPath:
			'eu/harmonie/cloud/{runsFolder}merc/{runTimestamp}{fileTimestamp}.png',
		BackgroundPath: 'images/map-eu-550x512-mercator.png',
		LogoPath: 'images/logo-white.png',
		ImageInterval: 60,
		ForecastProcessorName: 'renderer_cloud_eu_harmonie',
		Projection: 'merc',
		IsUTC: true,
	},
	[ImageRadars.RadarMapTemperature24HourForecastEU]: {
		DefaultHeight: 512,
		MaxHeight: 512,
		DefaultWidth: 550,
		MaxWidth: 550,
		DefaultHistory: 0,
		MaxHistory: 0,
		DefaultForecast: 12,
		MaxForecast: 48,
		Skip: 0,
		Opacity: 0.8,
		RenderBackground: true,
		SourceFileTemplate: 'yyyyMMddHHmm',
		ForecastPath:
			'eu/harmonie/temperature/{runsFolder}merc/{runTimestamp}{fileTimestamp}.png',
		BackgroundPath: 'images/map-eu-550x512-mercator.png',
		LogoPath: 'images/logo-white.png',
		ImageInterval: 60,
		ForecastProcessorName: 'renderer_temperature_eu_harmonie',
		Projection: 'merc',
		IsUTC: true,
	},
	[ImageRadars.RadarMapRainNLInterpolatedFc]: {
		DefaultHeight: 512,
		MaxHeight: 512,
		DefaultWidth: 550,
		MaxWidth: 550,
		DefaultHistory: 3,
		MaxHistory: 36,
		DefaultForecast: 3,
		MaxForecast: 36,
		Skip: 1,
		Opacity: 0.8,
		RenderBackground: true,
		SourceFileTemplate: 'yyyyMMddHHmm',
		HistoryPath: 'nl/rain/current/merc/{0}.png',
		ForecastPath:
			'nl/rain_fcinterpolated/forecast/{runsFolder}merc/{runTimestamp}{fileTimestamp}.png',
		BackgroundPath: 'images/map-nl-550x512-mercator.gif',
		OrangeBackgroundPath: 'images/map-nl-550x512-mercator.oranje.gif',
		LogoPath: 'images/logo-white.png',
		ImageInterval: 5,
		IsUTC: true,
		GifDelay: 500,
		HistoryProcessorName: 'render_rain_nl',
		ForecastProcessorName: 'render_rain_nl_interpolated_forecast',
		Projection: 'merc',
	},
	[ImageRadars.RadarMapRainWebmercatorNLInterpolatedFc]: {
		DefaultHeight: 915,
		MaxHeight: 915,
		DefaultWidth: 1058,
		MaxWidth: 1058,
		DefaultHistory: 3,
		MaxHistory: 12,
		DefaultForecast: 3,
		MaxForecast: 36,
		Skip: 1,
		Opacity: 0.8,
		RenderBackground: false,
		RenderText: false,
		BackgroundPath: 'images/map-nl-1058x916-webmercator.png',
		SourceFileTemplate: 'yyyyMMddHHmm',
		HistoryPath: 'nl/rain/current/webm/{0}.png',
		ForecastPath:
			'nl/rain_fcinterpolated/forecast/{runsFolder}webm/{runTimestamp}{fileTimestamp}.png',
		OrangeBackgroundPath: 'images/map-nl-1820x1575-webmercator.orange.png',
		LogoPath: 'images/logo-white.png',
		UsePngQuantizer: false,
		ImageInterval: 5,
		IsUTC: true,
		HistoryProcessorName: 'render_rain_nl',
		ForecastProcessorName: 'render_rain_nl_interpolated_forecast',
		Projection: 'webm',
	},
} as RadarImagesConfig;
