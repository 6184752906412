import { radarImagesConfig } from 'config/radarImagesConfig';
import axios from 'redaxios';
import { ImageLiteMetadata } from 'types/imageLiteApi';
import { ImageLiteRadars, ImageRadars } from 'types/imageRadars';

export interface IRadarImagesByType {
  type: ImageLiteRadars | ImageRadars;
  options?: {
    size?: 'Full' | 'Medium' | 'Thumbnail';
    forecast?: number;
    history?: number;
    subtype?: 'nl' | 'be';
  };
}

export const getRadarImagesByType = async ({
  type,
  options,
}: IRadarImagesByType): Promise<ImageLiteMetadata> => {
  const definedProps = (obj = {}) =>
    Object.fromEntries(Object.entries(obj).filter(([, v]) => v !== undefined));
  const isImageLite = Object.values(ImageLiteRadars).includes(
    type as ImageLiteRadars
  );

  let defaultOptions;
  if (!isImageLite) {
    defaultOptions = {
      renderText: radarImagesConfig[type]?.RenderText ?? false,
      renderBranding: radarImagesConfig[type]?.RenderBranding ?? false,
      renderBackground: radarImagesConfig[type]?.RenderBackground ?? true,
      width: radarImagesConfig[type].DefaultWidth,
      height: radarImagesConfig[type].DefaultHeight,
      subType: radarImagesConfig[type]?.SubType,
      forecast: radarImagesConfig[type].DefaultForecast,
      history: radarImagesConfig[type].DefaultHistory,
      skip: radarImagesConfig[type].Skip,
    };
  } else {
    const legacyDefaultOptions = radarImagesConfig[type];
    defaultOptions = {
      history: legacyDefaultOptions.DefaultHistory,
      forecast: legacyDefaultOptions.DefaultForecast,
      subtype: legacyDefaultOptions.SubType ?? '',
    };
  }

  const imageAPIUrl = isImageLite
    ? `https://image-lite.buienradar.nl/3.0/metadata/${type}?`
    : `https://image.buienradar.nl/2.0/metadata/animation/${type}?`;

  const requestOptions = Object.assign(defaultOptions, definedProps(options));
  const urlParams = new URLSearchParams(requestOptions).toString();

  const res = await axios.get(`${imageAPIUrl}${urlParams}`);
  return res.data;
};
