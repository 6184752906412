import Image from 'next/legacy/image';
import { useState } from 'react';
import { useQuery } from 'react-query';
import { MaincontentSettings } from 'types/siteAPI';
import { datalayerPush } from 'utils/analytics';
import { refreshAds } from 'utils/loadAdvertisements';
import { getRadarData } from './MapContainer.helpers';
import { TabsProps } from './MapContainer.types';

import styles from './MapContainer.module.scss';

export function Tabs({ settings, selectedTab, setSelectedTab }: TabsProps) {
	const [preloadTabIndex, setPreLoadTabIndex] = useState<number>(0);
	const { buttons } = settings;

	return (
		<div className={styles.radarButtons}>
			{preloadTabIndex ? (
				<Preloader index={preloadTabIndex} settings={settings} />
			) : null}
			{buttons.map((button, index) => {
				const isActive = selectedTab === index;

				return (
					<button
						key={button.name}
						className={styles.radarButton}
						data-isactive={isActive}
						onClick={() => {
							setSelectedTab(index);
							refreshAds();
							datalayerPush({
								event: 'MapSelectorTabClick',
								MapSelectorTabActive: button.name,
							});
						}}
						onMouseOver={() => setPreLoadTabIndex(index)}
						onFocus={() => setPreLoadTabIndex(index)}
						onTouchStart={() => setPreLoadTabIndex(index)}
					>
						{button.name}
					</button>
				);
			})}
		</div>
	);
}

function Preloader({
	index,
	settings,
}: {
	index: number;
	settings: MaincontentSettings;
}) {
	const radarToPreload = settings.buttons[index].settings?.type;
	const { data, isLoading, isError } = useQuery(
		['radarImages', radarToPreload],
		() => getRadarData({ radarType: radarToPreload }),
		{
			enabled: index > 0 && radarToPreload !== undefined,
		}
	);

	if (!data || isLoading || isError) return null;

	return (
		<>
			<div className="visually-hidden">
				{data.times.map((item) => (
					<Image
						key={item.timestamp}
						src={item.url}
						alt=""
						layout="fill"
						loading="eager"
					/>
				))}
			</div>
		</>
	);
}
