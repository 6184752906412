import dynamic from 'next/dynamic';
import { useState } from 'react';
import { EnumLegendMap } from './types';
import ArrowIcon from '/public/icons/arrow.svg';

import styles from './RadarLegend.module.scss';

const LegendPrecipitation = dynamic(() => import('./Legend/Precipitation'));
const LegendPrecipitation7Days = dynamic(
	() => import('./Legend/Precipitation7days')
);
const LegendLightning = dynamic(() => import('./Legend/Lightning'));
const LegendTemperature = dynamic(() => import('./Legend/Temperature'));
const LegendWind = dynamic(() => import('./Legend/Wind'));
const LegendWindGusts = dynamic(() => import('./Legend/WindGusts'));
const LegendCloudHeight = dynamic(() => import('./Legend/CloudHeight'));
const LegendWeatherMapTemperature = dynamic(
	() => import('./Legend/WeatherMapTemperature')
);
const LegendAirQuality = dynamic(() => import('./Legend/Airquality'));
const LegendAirQualityPM10 = dynamic(() => import('./Legend/Airqualitypm10'));
const LegendAirQualityO3 = dynamic(() => import('./Legend/Airqualityo3'));
const LegendAirQualityNO2 = dynamic(() => import('./Legend/Airqualityno2'));
const LegendWeatherMapPrecipitation = dynamic(
	() => import('./Legend/WeatherMapPrecipitation')
);
const LegendWeatherMapHumidity = dynamic(
	() => import('./Legend/WeatherMapHumidity')
);
const LegendWeatherMapSun = dynamic(() => import('./Legend/WeatherMapSun'));
const LegendEarthquakes = dynamic(() => import('./Legend/Earthquakes'));
const LegendMosquitoRadar = dynamic(() => import('./Legend/Mosquito'));
const LegendUVIndex = dynamic(() => import('./Legend/UVIndex'));
const LegendBBQRadar = dynamic(() => import('./Legend/BBQ'));
const LegendPollenRadar = dynamic(() => import('./Legend/Pollen'));
const LegendTravelAdvice = dynamic(() => import('./Legend/TravelAdvice'));
const LegendSnowDepth = dynamic(() => import('./Legend/SnowDepth'));

export function RadarLegend({ legendType }: { legendType: EnumLegendMap }) {
	const [showLegend, setShowLegend] = useState<boolean>(false);

	return (
		<div className={styles.legendContainer}>
			<Legend legendType={legendType} showLegend={showLegend} />
			<button
				type="button"
				className={styles.legendButton}
				data-active={showLegend}
				onClick={() => setShowLegend((current) => !current)}
			>
				Legenda
				<ArrowIcon />
			</button>
		</div>
	);
}

function Legend({
	legendType,
	showLegend,
}: {
	legendType: EnumLegendMap;
	showLegend: boolean;
}) {
	function renderLegend(legendType) {
		switch (legendType) {
			case EnumLegendMap['precipitation']:
			case EnumLegendMap['precipitation-drizzle']:
			case EnumLegendMap['precipitation-hail']:
			case EnumLegendMap['precipitation-snow']:
			case EnumLegendMap['precipitation-wetsnow-snow']:
			case EnumLegendMap['precipitation-lightning']:
				return <LegendPrecipitation type={legendType} />;
			case EnumLegendMap['precipitation-7days']:
				return <LegendPrecipitation7Days />;
			case EnumLegendMap['lightning']:
				return <LegendLightning />;
			case EnumLegendMap['temperature']:
				return <LegendTemperature />;
			case EnumLegendMap['wind']:
			case EnumLegendMap['weathermap-wind']:
				return <LegendWind />;
			case EnumLegendMap['weathermap-windgusts']:
				return <LegendWindGusts />;
			case EnumLegendMap['cloudheight']:
				return <LegendCloudHeight />;
			case EnumLegendMap['weathermap-temperature']:
				return <LegendWeatherMapTemperature />;
			case EnumLegendMap['airquality-index']:
				return <LegendAirQuality />;
			case EnumLegendMap['airquality-pm10']:
				return <LegendAirQualityPM10 />;
			case EnumLegendMap['airquality-o3']:
				return <LegendAirQualityO3 />;
			case EnumLegendMap['airquality-no2']:
				return <LegendAirQualityNO2 />;
			case EnumLegendMap['weathermap-precipitation']:
				return <LegendWeatherMapPrecipitation />;
			case EnumLegendMap['weathermap-humidity']:
				return <LegendWeatherMapHumidity />;
			case EnumLegendMap['weathermap-sun']:
				return <LegendWeatherMapSun />;
			case EnumLegendMap['earthquakes']:
				return <LegendEarthquakes />;
			case EnumLegendMap['mosquitoradar']:
				return <LegendMosquitoRadar />;
			case EnumLegendMap['uvindex']:
				return <LegendUVIndex />;
			case EnumLegendMap['bbqradar']:
				return <LegendBBQRadar />;
			case EnumLegendMap['pollen']:
				return <LegendPollenRadar />;
			case EnumLegendMap['traveladvice']:
				return <LegendTravelAdvice />;
			case EnumLegendMap['snowdepth']:
				return <LegendSnowDepth />;
			default:
				return null;
		}
	}

	return (
		<div
			className={styles.legend}
			data-active={showLegend}
			data-cy="radar-legend"
		>
			{renderLegend(legendType)}
		</div>
	);
}
