import { IRadarImagesByType, getRadarImagesByType } from 'utils/getRadarImages';

export function getLocalTime(utcTime: string) {
	const utc = new Date(utcTime + 'Z');

	return utc.toLocaleTimeString('nl-NL', {
		hour: '2-digit',
		minute: '2-digit',
	});
}

export async function getRadarData({ radarType }) {
	const options = {} as IRadarImagesByType['options'];
	return await getRadarImagesByType({ type: radarType, options });
}
