import Image from 'next/legacy/image';
import dynamic from 'next/dynamic';
import getConfig from 'next/config';
import React, { useState, useRef, useEffect } from 'react';
import { useQuery } from 'react-query';
import { AppLocationEnum } from 'types/app';
import { Maincontent } from 'types/siteAPI';
import useAppLocation from 'utils/hooks/useAppLocation';
import { useLocationState } from 'utils/contexts/LocationsContext';
import { Radars } from 'types/imageRadars';
import { Tabs } from './Tabs';

import PauseIcon from '/public/icons/pause.svg';
import PlayIcon from '/public/icons/play.svg';
import ZoomInIcon from '/public/icons/light/zoom-in.svg';
import ZoomOutIcon from '/public/icons/light/zoom-out.svg';

import {
	RadarImagesProps,
	RadarPlayoutControlProps,
	RadarProps,
	TimeProps,
	ZoomButtonProps,
	ZoomProps,
} from './MapContainer.types';
import { RadarLegend } from 'components/RadarLegend/RadarLegend';
import { EnumLegendMap } from 'components/RadarLegend/types';
import { getLocalTime, getRadarData } from './MapContainer.helpers';

import styles from './MapContainer.module.scss';

const MapMarker = dynamic(() => import('components/MapMarker/MapMarker'));

export default function MapContainer({ content }: { content: Maincontent }) {
	const appLocation = useAppLocation();
	const [selectedTab, setSelectedTab] = useState<number>(0);
	const { settings } = content;
	if (!settings) return null;
	const startingRadarMap =
		appLocation === 'BE'
			? Radars.RadarMapRain5mBE
			: Radars.RadarMapRain5mNL;
	const { buttons } = settings;
	const defaultRadarMap =
		buttons[0].name === 'Buienradar'
			? startingRadarMap
			: (buttons[0].settings?.type as Radars);

	const selectedRadarSettings = settings.buttons[selectedTab].settings;
	if (!selectedRadarSettings) return null;
	const selectedRadar =
		selectedTab === 0
			? defaultRadarMap
			: (selectedRadarSettings.type as Radars);

	return (
		<section className={styles.mapContainer}>
			<Tabs
				settings={settings}
				selectedTab={selectedTab}
				setSelectedTab={setSelectedTab}
			/>
			<Radar
				appLocation={appLocation}
				settings={settings}
				selectedRadarSettings={selectedRadarSettings}
				selectedRadar={selectedRadar}
			/>
		</section>
	);
}

function Radar({
	appLocation,
	settings,
	selectedRadarSettings,
	selectedRadar,
}: RadarProps) {
	const { data, isLoading } = useQuery(
		['radarImages', selectedRadar],
		() => getRadarData({ radarType: selectedRadar }),
		{
			keepPreviousData: true,
		}
	);
	const imageWrapperRef = useRef<HTMLDivElement | null>(null);
	const imageWrapperSize = {
		width: imageWrapperRef.current?.getBoundingClientRect().width ?? 550,
		height: imageWrapperRef.current?.getBoundingClientRect().height ?? 495,
	};
	const [position, setPosition] = useState<number>(0);
	const [isPlaying, setIsPlaying] = useState<boolean>(true);
	const { currentLocation, favorites } = useLocationState();

	if (isLoading || !data) return null;

	return (
		<>
			<div className={styles.imageContainer}>
				<Time data={data} position={position} />
				<MapMarker
					locations={favorites}
					radar={selectedRadar}
					size={imageWrapperSize}
				/>
				<Zoom
					currentLocation={currentLocation}
					settings={settings}
					radarSettings={selectedRadarSettings}
				/>
				{selectedRadarSettings?.legend ? (
					<RadarLegend
						legendType={
							selectedRadarSettings.legend as EnumLegendMap
						}
					/>
				) : null}
				<div className={styles.radarImages}>
					<div
						style={{
							transform: `translateX(-${
								imageWrapperSize.width * position
							}px)`,
						}}
						className={styles.radarImagesWrapper}
						ref={imageWrapperRef}
					>
						<MemoRadarImages
							data={data}
							setIsPlaying={setIsPlaying}
						/>
					</div>
				</div>
				<RadarBackgroundImage appLocation={appLocation} />
			</div>
			<RadarPlayoutControl
				data={data}
				position={position}
				setPosition={setPosition}
				isPlaying={isPlaying}
				setIsPlaying={setIsPlaying}
			/>
		</>
	);
}

function RadarImages({ data, setIsPlaying }: RadarImagesProps) {
	const [imagesLoaded, setImagesLoaded] = useState<number>(0);

	useEffect(() => {
		setIsPlaying(false);
		if (imagesLoaded >= data.times.length / 2) {
			setIsPlaying(true);
		}
	}, [data, imagesLoaded, setIsPlaying]);

	useEffect(() => {
		setImagesLoaded(0);
	}, [data]);

	return (
		<>
			{data?.times.map((item) => {
				return (
					<div className={styles.radarImage} key={item.timestamp}>
						<Image
							src={item.url}
							alt=""
							layout="fill"
							priority={true}
							onLoadingComplete={() => {
								setImagesLoaded((prevState) => prevState + 1);
							}}
						/>
					</div>
				);
			})}
		</>
	);
}

const MemoRadarImages = React.memo(RadarImages);

function RadarBackgroundImage({
	appLocation,
}: {
	appLocation: AppLocationEnum;
}) {
	const { publicRuntimeConfig } = getConfig();
	const {
		api: { imageLite },
	} = publicRuntimeConfig;
	const backgroundType =
		appLocation === 'BE' ? 'MapWebmercatorBe' : 'MapWebmercatorNl';

	return (
		<div className={styles.radarBackgroundImage}>
			<Image
				src={`${imageLite}/3.0/background/${backgroundType}`}
				priority={true}
				alt=""
				layout="fill"
			/>
		</div>
	);
}

function RadarPlayoutControl({
	data,
	position,
	setPosition,
	isPlaying,
	setIsPlaying,
}: RadarPlayoutControlProps) {
	const radarInterval = useRef<NodeJS.Timer | null>(null);

	useEffect(() => {
		setPosition(0);
	}, [data, setPosition]);

	useEffect(() => {
		if (data?.times.length > 0) {
			const tick = () => {
				if (position === data.times.length - 1) {
					setPosition(0);
				} else {
					setPosition(position + 1);
				}
			};

			if (isPlaying) {
				radarInterval.current = setInterval(tick, 800);
			}
		}
		return () => {
			if (radarInterval.current) {
				clearInterval(radarInterval.current);
			}
		};
	}, [position, data, isPlaying, setPosition]);

	const handleDrag = (val) => {
		setIsPlaying(false);
		setPosition(val);
	};

	if (!data?.times) return null;
	const mod = data.times.length > 12 ? 5 : 2;

	return (
		<div className={styles.playoutControlContainer}>
			<button
				type="button"
				className={styles.playButton}
				data-isplaying={isPlaying}
				onClick={() => setIsPlaying(!isPlaying)}
				title={isPlaying ? 'pauzeren' : 'afspelen'}
			>
				{isPlaying ? (
					<PauseIcon title="pauzeren" />
				) : (
					<PlayIcon title="afspelen" />
				)}
			</button>
			<div className={styles.sliderContainer}>
				<div className={styles.intervalMarks}>
					{data.times.map((d, i) => (
						<div key={d.timestamp} style={{ position: 'relative' }}>
							<div
								className={styles.intervalMark}
								data-isactive={i === position}
							/>
							{i % mod === 0 ? (
								<div className={styles.intervalTimestamp}>
									{getLocalTime(d.timestamp)}
								</div>
							) : null}
						</div>
					))}
				</div>
				<div className={styles.playoutSlider} />
				<input
					className={styles.rangeSlider}
					type="range"
					min="0"
					max={data.times.length - 1}
					onChange={(e) => handleDrag(e.target.valueAsNumber)}
					value={position}
				/>
			</div>
		</div>
	);
}

function Zoom({ settings, radarSettings, currentLocation }: ZoomProps) {
	if (!radarSettings.zoomInUrl) return null;

	return (
		<div className={styles.zoomContainer}>
			{settings.zoomInUrl ? (
				<ZoomButton
					currentLocation={currentLocation}
					type="in"
					url={radarSettings.zoomInUrl}
				/>
			) : null}
			{settings.zoomOutUrl ? (
				<ZoomButton type="out" url={settings.zoomOutUrl} />
			) : null}
		</div>
	);
}

function ZoomButton({ url, type, currentLocation }: ZoomButtonProps) {
	if (
		currentLocation &&
		currentLocation?.location?.lon &&
		currentLocation?.location?.lat
	) {
		url = `${url}?lat=${currentLocation.location.lat.toFixed(
			3
		)}&lon=${currentLocation.location.lon.toFixed(3)}`;
	}
	const title = type === 'in' ? 'Zoom in' : 'Zoom uit';
	return (
		<a href={url} className={styles.zoomButton} title={title}>
			{type === 'in' ? (
				<ZoomInIcon title={title} />
			) : (
				<ZoomOutIcon title={title} />
			)}
		</a>
	);
}

function Time({ data, position }: TimeProps) {
	if (!data?.times || position >= data.times.length) return null;

	const { timestamp } = data.times[position];
	const newTimestamp = getLocalTime(timestamp);

	return (
		<time className={styles.timestamp} dateTime={newTimestamp}>
			{newTimestamp}
		</time>
	);
}
