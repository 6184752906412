export enum EnumLegendMap {
  'precipitation' = 'precipitation',
  'precipitation-drizzle' = 'precipitation-drizzle',
  'precipitation-hail' = 'precipitation-hail',
  'precipitation-snow' = 'precipitation-snow',
  'precipitation-wetsnow-snow' = 'precipitation-wetsnow-snow',
  'precipitation-lightning' = 'precipitation-lightning',
  'precipitation-7days' = 'precipitation-7days',
  'lightning' = 'lightning',
  'temperature' = 'temperature',
  'wind' = 'wind',
  'snowdepth' = 'snowdepth',
  'cloudheight' = 'cloudheight',
  'weathermap-temperature' = 'weathermap-temperature',
  'airquality-index' = 'airquality-index',
  'airquality-pm10' = 'airquality-pm10',
  'airquality-o3' = 'airquality-o3',
  'airquality-no2' = 'airquality-no2',
  'weathermap-wind' = 'weathermap-wind',
  'weathermap-windgusts' = 'weathermap-windgusts',
  'weathermap-precipitation' = 'weathermap-precipitation',
  'weathermap-humidity' = 'weathermap-humidity',
  'weathermap-sun' = 'weathermap-sun',
  'earthquakes' = 'earthquakes',
  'mosquitoradar' = 'mosquitoradar',
  'uvindex' = 'uvindex',
  'bbqradar' = 'bbqradar',
  'pollen' = 'pollen',
  'traveladvice' = 'traveladvice',
}
