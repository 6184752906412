export enum ImageLiteRadars {
	RadarMapRain5mNL = 'RadarMapRain5mNL',
	RadarMapCloudHeight5mNL = 'RadarMapCloudHeight5mNL',
	WeatherMapTemperature1hNL = 'WeatherMapTemperature1hNL',
	AirQualityMapAQI1hNl = 'AirQualityMapAQI1hNl',
	AirQualityMapNO21hNl = 'AirQualityMapNO21hNl',
	AirQualityMapO31hNl = 'AirQualityMapO31hNl',
	AirQualityMapPM101hNl = 'AirQualityMapPM101hNl',
	AirQualityMapPM2P51hNl = 'AirQualityMapPM2P51hNl',
	RadarMapRain5mBE = 'RadarMapRain5mBE',
	RadarMapRain15mBE = 'RadarMapRain15mBE',
	RadarMapRain1hBE = 'RadarMapRain1hBE',
	RadarMapDrizzle5mBE = 'RadarMapDrizzle5mBE',
	RadarMapHail5mBE = 'RadarMapHail5mBE',
	RadarMapLightning5mBE = 'RadarMapLightning5mBE',
	RadarMapRain15mNWEU = 'RadarMapRain15mNWEU',
	RadarMapRain3hEU = 'RadarMapRain3hEU',
	RadarMapCloud3hEU = 'RadarMapCloud3hEU',
	RadarMapLightningChance3hEU = 'RadarMapLightningChance3hEU',
	RadarMapTemperature3hEU = 'RadarMapTemperature3hEU',
	RadarMapWindPressure3hEU = 'RadarMapWindPressure3hEU',
	RadarMapSnowDepth3hEU = 'RadarMapSnowDepth3hEU',
	WeatherMapTemperature1hBE = 'WeatherMapTemperature1hBE',
	WeatherMapActualTemperature1hBE = 'WeatherMapActualTemperature1hBE',
	WeatherMapFeelTemperature1hBE = 'WeatherMapFeelTemperature1hBE',
	WeatherMapMinTemperature1hBE = 'WeatherMapMinTemperature1hBE',
	WeatherMapMaxTemperature1hBE = 'WeatherMapMaxTemperature1hBE',
	WeatherMapUv1dBE = 'WeatherMapUv1dBE',
	WeatherMapPollen1dBE = 'WeatherMapPollen1dBE',
	WeatherMapPollen1hBE = 'WeatherMapPollen1hBE',
	WeatherMapHumidity1hBE = 'WeatherMapHumidity1hBE',
	WeatherMapMosquito1dBE = 'WeatherMapMosquito1dBE',
	WeatherMapVisibility1hBE = 'WeatherMapVisibility1hBE',
	WeatherMapBBQ1dBE = 'WeatherMapBBQ1dBE',
	WeatherMapWind1hBE = 'WeatherMapWind1hBE',
	WeatherMapMaxWind1hBE = 'WeatherMapMaxWind1hBE',
	WeatherMapWindGusts1hBE = 'WeatherMapWindGusts1hBE',
	SatMapVisual15m = 'SatMapVisual15m',
	SatMapVisualLightning15m = 'SatMapVisualLightning15m',
	SatMapInfrared15m = 'SatMapInfrared15m',
	SatMapInfraredLightning15m = 'SatMapInfraredLightning15m',
	SatMapCombined15m = 'SatMapCombined15m',
	RadarMapRain15mWorld = 'RadarMapRain15mWorld',
	SatMapVisual30mAsiaOceania = 'SatMapVisual30mAsiaOceania',
	SatMapInfrared30mAsiaOceania = 'SatMapInfrared30mAsiaOceania',
	SatMapVisual2hNorthAmerica = 'SatMapVisual2hNorthAmerica',
	SatMapInfrared2hNorthAmerica = 'SatMapInfrared2hNorthAmerica',
	SatMapVisual6hSouthAmerica = 'SatMapVisual6hSouthAmerica',
	SatMapInfrared6hSouthAmerica = 'SatMapInfrared6hSouthAmerica',
	RadarMapSun5mBE = 'RadarMapSun5mBE',
	RadarMapCloud5mBE = 'RadarMapCloud5mBE',
	RadarMapRain15mEU = 'RadarMapRain15mEU',
	RadarMapSnow15mEU = 'RadarMapSnow15mEU',
	RadarMapCloudLightning15mEU = 'RadarMapCloudLightning15mEU',
	RadarMapRain5mEU = 'RadarMapRain5mEU',
	RadarMapSnow5mEU = 'RadarMapSnow5mEU',
	RadarMapRain1hEU = 'RadarMapRain1hEU',
	RadarMapSnow5mBE = 'RadarMapSnow5mBE',
}

export enum ImageRadars {
	AirQualityWebmercatorNL = 'AirQualityWebmercatorNL',
	AnwbMapRainNL = 'AnwbMapRainNL',
	RadarMapCloud24HourForecastBE = 'RadarMapCloud24HourForecastBE',
	RadarMapCloud24HourForecastEU = 'RadarMapCloud24HourForecastEU',
	RadarMapCloud24HourForecastNL = 'RadarMapCloud24HourForecastNL',
	RadarMapCloud24HourForecastWebmercatorBE = 'RadarMapCloud24HourForecastWebmercatorBE',
	RadarMapCloud24HourForecastWebmercatorNL = 'RadarMapCloud24HourForecastWebmercatorNL',
	RadarMapCloudBE = 'RadarMapCloudBE',
	RadarMapCloudEU = 'RadarMapCloudEU',
	RadarMapCloudHeight5mNL = 'RadarMapCloudHeight5mNL',
	RadarMapCloudNL = 'RadarMapCloudNL',
	RadarMapCloud5mBE = 'RadarMapCloud5mBE',
	RadarMapCloudWebmercatorGfsEU = 'RadarMapCloudWebmercatorGfsEU',
	RadarMapCloudWebmercatorNL = 'RadarMapCloudWebmercatorNL',
	RadarMapDrizzleBE = 'RadarMapDrizzleBE',
	RadarMapDrizzleNL = 'RadarMapDrizzleNL',
	RadarMapDrizzle5mBE = 'RadarMapDrizzle5mBE',
	RadarMapDrizzleWebmercatorNL = 'RadarMapDrizzleWebmercatorNL',
	RadarMapHailBE = 'RadarMapHailBE',
	RadarMapHailNL = 'RadarMapHailNL',
	RadarMapHail5mBE = 'RadarMapHail5mBE',
	RadarMapHailWebmercatorNL = 'RadarMapHailWebmercatorNL',
	RadarMapLightningBE = 'RadarMapLightningBE',
	RadarMapLightningNL = 'RadarMapLightningNL',
	RadarMapLightning5mBE = 'RadarMapLightning5mBE',
	RadarMapLightningWebmercatorNL = 'RadarMapLightningWebmercatorNL',
	RadarMapRain24HourForecastBE = 'RadarMapRain24HourForecastBE',
	RadarMapRain24HourForecastEU = 'RadarMapRain24HourForecastEU',
	RadarMapRain24HourForecastNL = 'RadarMapRain24HourForecastNL',
	RadarMapRain24HourForecastWebmercatorNL = 'RadarMapRain24HourForecastWebmercatorNL',
	RadarMapRain24HourHistoryNL = 'RadarMapRain24HourHistoryNL',
	RadarMapRain3HourForecastNL = 'RadarMapRain3HourForecastNL',
	RadarMapRainBE = 'RadarMapRainBE',
	RadarMapRainBEEightHour = 'RadarMapRainBEEightHour',
	RadarMapRainCombinedWebmercatorEU = 'RadarMapRainCombinedWebmercatorEU',
	RadarMapRainEU = 'RadarMapRainEU',
	RadarMapRainNL = 'RadarMapRainNL',
	RadarMapRainNLEightHour = 'RadarMapRainNLEightHour',
	RadarMapRainNLInterpolatedFc = 'RadarMapRainNLInterpolatedFc',
	RadarMapRain15mBE = 'RadarMapRain15mBE',
	RadarMapRain15mNWEU = 'RadarMapRain15mNWEU',
	RadarMapRainWebmercatorNLEightHour = 'RadarMapRainWebmercatorNLEightHour',
	RadarMapRainWebmercatorNLInterpolatedFc = 'RadarMapRainWebmercatorNLInterpolatedFc',
	RadarMapSnowBE = 'RadarMapSnowBE',
	RadarMapSnowEU = 'RadarMapSnowEU',
	RadarMapSnowNL = 'RadarMapSnowNL',
	RadarMapSnowWebmercatorBE = 'RadarMapSnowWebmercatorBE',
	RadarMapSnowWebmercatorEU = 'RadarMapSnowWebmercatorEU',
	RadarMapSnowWebmercatorNL = 'RadarMapSnowWebmercatorNL',
	RadarMapSun24HourForecastBE = 'RadarMapSun24HourForecastBE',
	RadarMapSun24HourForecastEU = 'RadarMapSun24HourForecastEU',
	RadarMapSun24HourForecastNL = 'RadarMapSun24HourForecastNL',
	RadarMapSun24HourForecastWebmercatorBE = 'RadarMapSun24HourForecastWebmercatorBE',
	RadarMapSun24HourForecastWebmercatorNL = 'RadarMapSun24HourForecastWebmercatorNL',
	RadarMapSunBE = 'RadarMapSunBE',
	RadarMapSunNL = 'RadarMapSunNL',
	RadarMapSun5mBE = 'RadarMapSun5mBE',
	RadarMapSunWebmercatorNL = 'RadarMapSunWebmercatorNL',
	RadarMapTemperature24HourForecastBE = 'RadarMapTemperature24HourForecastBE',
	RadarMapTemperature24HourForecastEU = 'RadarMapTemperature24HourForecastEU',
	RadarMapTemperature24HourForecastNL = 'RadarMapTemperature24HourForecastNL',
	RadarMapTemperature24HourForecastWebmercatorBE = 'RadarMapTemperature24HourForecastWebmercatorBE',
	RadarMapTemperature24HourForecastWebmercatorNL = 'RadarMapTemperature24HourForecastWebmercatorNL',
	SatAsiaOceania = 'SatAsiaOceania',
	SatNorthAmerica = 'SatNorthAmerica',
	SatRainCombined = 'SatRainCombined',
	SatSouthAmerica = 'SatSouthAmerica',
	WeatherMapAirQualityLKINL = 'WeatherMapAirQualityLKINL',
	WeatherMapAirQualityNO2NL = 'WeatherMapAirQualityNO2NL',
	WeatherMapAirQualityOzoneNL = 'WeatherMapAirQualityOzoneNL',
	WeatherMapAirQualityPM10NL = 'WeatherMapAirQualityPM10NL',
	WeatherMapBBQRadarBE = 'WeatherMapBBQRadarBE',
	WeatherMapBBQRadarNL = 'WeatherMapBBQRadarNL',
	WeatherMapHumidityBE = 'WeatherMapHumidityBE',
	WeatherMapHumidityNL = 'WeatherMapHumidityNL',
	WeatherMapMosquitoRadarBE = 'WeatherMapMosquitoRadarBE',
	WeatherMapMosquitoRadarNL = 'WeatherMapMosquitoRadarNL',
	WeatherMapPollenRadarBE = 'WeatherMapPollenRadarBE',
	WeatherMapPollenRadarHourlyBE = 'WeatherMapPollenRadarHourlyBE',
	WeatherMapPollenRadarHourlyNL = 'WeatherMapPollenRadarHourlyNL',
	WeatherMapPollenRadarNL = 'WeatherMapPollenRadarNL',
	WeatherMapRainfallLast24HoursNL = 'WeatherMapRainfallLast24HoursNL',
	WeatherMapRainfallLastHourBE = 'WeatherMapRainfallLastHourBE',
	WeatherMapRainfallLastHourNL = 'WeatherMapRainfallLastHourNL',
	WeatherMapSunshineNL = 'WeatherMapSunshineNL',
	WeatherMapTemperatureActualNL = 'WeatherMapTemperatureActualNL',
	WeatherMapTemperatureFeelNL = 'WeatherMapTemperatureFeelNL',
	WeatherMapTemperatureGroundMinNL = 'WeatherMapTemperatureGroundMinNL',
	WeatherMapTemperatureGroundNL = 'WeatherMapTemperatureGroundNL',
	WeatherMapTemperatureMaxNL = 'WeatherMapTemperatureMaxNL',
	WeatherMapTemperatureMinNL = 'WeatherMapTemperatureMinNL',
	WeatherMapTotalRainfallForDateBE = 'WeatherMapTotalRainfallForDateBE',
	WeatherMapTotalRainfallForDateNL = 'WeatherMapTotalRainfallForDateNL',
	WeatherMapUVIndexBE = 'WeatherMapUVIndexBE',
	WeatherMapUVIndexNL = 'WeatherMapUVIndexNL',
	WeatherMapVisibilityBE = 'WeatherMapVisibilityBE',
	WeatherMapVisibilityNL = 'WeatherMapVisibilityNL',
	WeatherMapWindBE = 'WeatherMapWindBE',
	WeatherMapWindGustsBE = 'WeatherMapWindGustsBE',
	WeatherMapWindGustsMaxNL = 'WeatherMapWindGustsMaxNL',
	WeatherMapWindGustsNL = 'WeatherMapWindGustsNL',
	WeatherMapWindMaxBE = 'WeatherMapWindMaxBE',
	WeatherMapWindMaxNL = 'WeatherMapWindMaxNL',
	WeatherMapWindNL = 'WeatherMapWindNL',
	SatInfraRed = 'SatInfraRed',
	SatVisual = 'SatVisual',
	SatCombined = 'SatCombined',
}

export enum Radars {
	RadarMapRain5mNL = 'RadarMapRain5mNL',
	RadarMapCloudHeight5mNL = 'RadarMapCloudHeight5mNL',
	WeatherMapTemperature1hNL = 'WeatherMapTemperature1hNL',
	AirQualityMapAQI1hNl = 'AirQualityMapAQI1hNl',
	AirQualityMapNO21hNl = 'AirQualityMapNO21hNl',
	AirQualityMapO31hNl = 'AirQualityMapO31hNl',
	AirQualityMapPM101hNl = 'AirQualityMapPM101hNl',
	AirQualityMapPM2P51hNl = 'AirQualityMapPM2P51hNl',
	RadarMapRain5mBE = 'RadarMapRain5mBE',
	RadarMapRain15mBE = 'RadarMapRain15mBE',
	RadarMapRain1hBE = 'RadarMapRain1hBE',
	RadarMapDrizzle5mBE = 'RadarMapDrizzle5mBE',
	RadarMapSnow5mBE = 'RadarMapSnow5mBE',
	RadarMapHail5mBE = 'RadarMapHail5mBE',
	RadarMapLightning5mBE = 'RadarMapLightning5mBE',
	RadarMapRain15mNWEU = 'RadarMapRain15mNWEU',
	RadarMapRain3hEU = 'RadarMapRain3hEU',
	RadarMapCloud3hEU = 'RadarMapCloud3hEU',
	RadarMapLightningChance3hEU = 'RadarMapLightningChance3hEU',
	RadarMapTemperature3hEU = 'RadarMapTemperature3hEU',
	RadarMapWindPressure3hEU = 'RadarMapWindPressure3hEU',
	RadarMapSnowDepth3hEU = 'RadarMapSnowDepth3hEU',
	WeatherMapTemperature1hBE = 'WeatherMapTemperature1hBE',
	WeatherMapActualTemperature1hBE = 'WeatherMapActualTemperature1hBE',
	WeatherMapFeelTemperature1hBE = 'WeatherMapFeelTemperature1hBE',
	WeatherMapMinTemperature1hBE = 'WeatherMapMinTemperature1hBE',
	WeatherMapMaxTemperature1hBE = 'WeatherMapMaxTemperature1hBE',
	WeatherMapUv1dBE = 'WeatherMapUv1dBE',
	WeatherMapPollen1dBE = 'WeatherMapPollen1dBE',
	WeatherMapPollen1hBE = 'WeatherMapPollen1hBE',
	WeatherMapHumidity1hBE = 'WeatherMapHumidity1hBE',
	WeatherMapMosquito1dBE = 'WeatherMapMosquito1dBE',
	WeatherMapVisibility1hBE = 'WeatherMapVisibility1hBE',
	WeatherMapBBQ1dBE = 'WeatherMapBBQ1dBE',
	WeatherMapWind1hBE = 'WeatherMapWind1hBE',
	WeatherMapMaxWind1hBE = 'WeatherMapMaxWind1hBE',
	WeatherMapWindGusts1hBE = 'WeatherMapWindGusts1hBE',
	SatMapVisual15m = 'SatMapVisual15m',
	SatMapVisualLightning15m = 'SatMapVisualLightning15m',
	SatMapInfrared15m = 'SatMapInfrared15m',
	SatMapInfraredLightning15m = 'SatMapInfraredLightning15m',
	SatMapCombined15m = 'SatMapCombined15m',
	RadarMapRain15mWorld = 'RadarMapRain15mWorld',
	SatMapVisual30mAsiaOceania = 'SatMapVisual30mAsiaOceania',
	SatMapInfrared30mAsiaOceania = 'SatMapInfrared30mAsiaOceania',
	SatMapVisual2hNorthAmerica = 'SatMapVisual2hNorthAmerica',
	SatMapInfrared2hNorthAmerica = 'SatMapInfrared2hNorthAmerica',
	SatMapVisual6hSouthAmerica = 'SatMapVisual6hSouthAmerica',
	SatMapInfrared6hSouthAmerica = 'SatMapInfrared6hSouthAmerica',
	RadarMapSun5mBE = 'RadarMapSun5mBE',
	RadarMapCloud5mBE = 'RadarMapCloud5mBE',
	RadarMapRain15mEU = 'RadarMapRain15mEU',
	RadarMapSnow15mEU = 'RadarMapSnow15mEU',
	RadarMapCloudLightning15mEU = 'RadarMapCloudLightning15mEU',
	RadarMapRain5mEU = 'RadarMapRain5mEU',
	RadarMapSnow5mEU = 'RadarMapSnow5mEU',
	RadarMapRain1hEU = 'RadarMapRain1hEU',

	//image radars
	AirQualityWebmercatorNL = 'AirQualityWebmercatorNL',
	AnwbMapRainNL = 'AnwbMapRainNL',
	RadarMapCloud24HourForecastBE = 'RadarMapCloud24HourForecastBE',
	RadarMapCloud24HourForecastEU = 'RadarMapCloud24HourForecastEU',
	RadarMapCloud24HourForecastNL = 'RadarMapCloud24HourForecastNL',
	RadarMapCloud24HourForecastWebmercatorBE = 'RadarMapCloud24HourForecastWebmercatorBE',
	RadarMapCloud24HourForecastWebmercatorNL = 'RadarMapCloud24HourForecastWebmercatorNL',
	RadarMapCloudBE = 'RadarMapCloudBE',
	RadarMapCloudEU = 'RadarMapCloudEU',
	RadarMapCloudNL = 'RadarMapCloudNL',
	RadarMapCloudWebmercatorGfsEU = 'RadarMapCloudWebmercatorGfsEU',
	RadarMapCloudWebmercatorNL = 'RadarMapCloudWebmercatorNL',
	RadarMapDrizzleBE = 'RadarMapDrizzleBE',
	RadarMapDrizzleNL = 'RadarMapDrizzleNL',
	RadarMapDrizzleWebmercatorNL = 'RadarMapDrizzleWebmercatorNL',
	RadarMapHailBE = 'RadarMapHailBE',
	RadarMapHailNL = 'RadarMapHailNL',
	RadarMapHailWebmercatorNL = 'RadarMapHailWebmercatorNL',
	RadarMapLightningBE = 'RadarMapLightningBE',
	RadarMapLightningNL = 'RadarMapLightningNL',
	RadarMapLightningWebmercatorNL = 'RadarMapLightningWebmercatorNL',
	RadarMapRain24HourForecastBE = 'RadarMapRain24HourForecastBE',
	RadarMapRain24HourForecastEU = 'RadarMapRain24HourForecastEU',
	RadarMapRain24HourForecastNL = 'RadarMapRain24HourForecastNL',
	RadarMapRain24HourForecastWebmercatorNL = 'RadarMapRain24HourForecastWebmercatorNL',
	RadarMapRain24HourHistoryNL = 'RadarMapRain24HourHistoryNL',
	RadarMapRain3HourForecastNL = 'RadarMapRain3HourForecastNL',
	RadarMapRainBE = 'RadarMapRainBE',
	RadarMapRainBEEightHour = 'RadarMapRainBEEightHour',
	RadarMapRainCombinedWebmercatorEU = 'RadarMapRainCombinedWebmercatorEU',
	RadarMapRainEU = 'RadarMapRainEU',
	RadarMapRainNL = 'RadarMapRainNL',
	RadarMapRainNLEightHour = 'RadarMapRainNLEightHour',
	RadarMapRainNLInterpolatedFc = 'RadarMapRainNLInterpolatedFc',
	RadarMapRainWebmercatorNLEightHour = 'RadarMapRainWebmercatorNLEightHour',
	RadarMapRainWebmercatorNLInterpolatedFc = 'RadarMapRainWebmercatorNLInterpolatedFc',
	RadarMapSnowBE = 'RadarMapSnowBE',
	RadarMapSnowEU = 'RadarMapSnowEU',
	RadarMapSnowNL = 'RadarMapSnowNL',
	RadarMapSnowWebmercatorBE = 'RadarMapSnowWebmercatorBE',
	RadarMapSnowWebmercatorEU = 'RadarMapSnowWebmercatorEU',
	RadarMapSnowWebmercatorNL = 'RadarMapSnowWebmercatorNL',
	RadarMapSun24HourForecastBE = 'RadarMapSun24HourForecastBE',
	RadarMapSun24HourForecastEU = 'RadarMapSun24HourForecastEU',
	RadarMapSun24HourForecastNL = 'RadarMapSun24HourForecastNL',
	RadarMapSun24HourForecastWebmercatorBE = 'RadarMapSun24HourForecastWebmercatorBE',
	RadarMapSun24HourForecastWebmercatorNL = 'RadarMapSun24HourForecastWebmercatorNL',
	RadarMapSunBE = 'RadarMapSunBE',
	RadarMapSunNL = 'RadarMapSunNL',
	RadarMapSunWebmercatorNL = 'RadarMapSunWebmercatorNL',
	RadarMapTemperature24HourForecastBE = 'RadarMapTemperature24HourForecastBE',
	RadarMapTemperature24HourForecastEU = 'RadarMapTemperature24HourForecastEU',
	RadarMapTemperature24HourForecastNL = 'RadarMapTemperature24HourForecastNL',
	RadarMapTemperature24HourForecastWebmercatorBE = 'RadarMapTemperature24HourForecastWebmercatorBE',
	RadarMapTemperature24HourForecastWebmercatorNL = 'RadarMapTemperature24HourForecastWebmercatorNL',
	SatAsiaOceania = 'SatAsiaOceania',
	SatNorthAmerica = 'SatNorthAmerica',
	SatRainCombined = 'SatRainCombined',
	SatSouthAmerica = 'SatSouthAmerica',
	WeatherMapAirQualityLKINL = 'WeatherMapAirQualityLKINL',
	WeatherMapAirQualityNO2NL = 'WeatherMapAirQualityNO2NL',
	WeatherMapAirQualityOzoneNL = 'WeatherMapAirQualityOzoneNL',
	WeatherMapAirQualityPM10NL = 'WeatherMapAirQualityPM10NL',
	WeatherMapBBQRadarBE = 'WeatherMapBBQRadarBE',
	WeatherMapBBQRadarNL = 'WeatherMapBBQRadarNL',
	WeatherMapHumidityBE = 'WeatherMapHumidityBE',
	WeatherMapHumidityNL = 'WeatherMapHumidityNL',
	WeatherMapMosquitoRadarBE = 'WeatherMapMosquitoRadarBE',
	WeatherMapMosquitoRadarNL = 'WeatherMapMosquitoRadarNL',
	WeatherMapPollenRadarBE = 'WeatherMapPollenRadarBE',
	WeatherMapPollenRadarHourlyBE = 'WeatherMapPollenRadarHourlyBE',
	WeatherMapPollenRadarHourlyNL = 'WeatherMapPollenRadarHourlyNL',
	WeatherMapPollenRadarNL = 'WeatherMapPollenRadarNL',
	WeatherMapRainfallLast24HoursNL = 'WeatherMapRainfallLast24HoursNL',
	WeatherMapRainfallLastHourBE = 'WeatherMapRainfallLastHourBE',
	WeatherMapRainfallLastHourNL = 'WeatherMapRainfallLastHourNL',
	WeatherMapSunshineNL = 'WeatherMapSunshineNL',
	WeatherMapTemperatureActualNL = 'WeatherMapTemperatureActualNL',
	WeatherMapTemperatureFeelNL = 'WeatherMapTemperatureFeelNL',
	WeatherMapTemperatureGroundMinNL = 'WeatherMapTemperatureGroundMinNL',
	WeatherMapTemperatureGroundNL = 'WeatherMapTemperatureGroundNL',
	WeatherMapTemperatureMaxNL = 'WeatherMapTemperatureMaxNL',
	WeatherMapTemperatureMinNL = 'WeatherMapTemperatureMinNL',
	WeatherMapTotalRainfallForDateBE = 'WeatherMapTotalRainfallForDateBE',
	WeatherMapTotalRainfallForDateNL = 'WeatherMapTotalRainfallForDateNL',
	WeatherMapUVIndexBE = 'WeatherMapUVIndexBE',
	WeatherMapUVIndexNL = 'WeatherMapUVIndexNL',
	WeatherMapVisibilityBE = 'WeatherMapVisibilityBE',
	WeatherMapVisibilityNL = 'WeatherMapVisibilityNL',
	WeatherMapWindBE = 'WeatherMapWindBE',
	WeatherMapWindGustsBE = 'WeatherMapWindGustsBE',
	WeatherMapWindGustsMaxNL = 'WeatherMapWindGustsMaxNL',
	WeatherMapWindGustsNL = 'WeatherMapWindGustsNL',
	WeatherMapWindMaxBE = 'WeatherMapWindMaxBE',
	WeatherMapWindMaxNL = 'WeatherMapWindMaxNL',
	WeatherMapWindNL = 'WeatherMapWindNL',
	SatInfraRed = 'SatInfraRed',
	SatVisual = 'SatVisual',
	SatCombined = 'SatCombined',
}
